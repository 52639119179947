import useLastUpdateDate from '~/libs/hooks/useLastUpdateDate'
import {
  useDemolishDataV1GovDemolishGet,
  useGetLandUsePlanDataV1GovLandUsePlanGet,
  useLandChangeOwnerDataV1GovLandChangeOwnerGet,
  useLandInfoDataV1GovLandGet,
  useLandPriceDataV1GovLandPriceGet,
  useLedgerFloorDataV1GovLedgerFloorGet,
  useLedgerOutlineDataV1GovLedgerOutlineGet,
  useLedgerOutlineSummaryDataV1GovLedgerOutlineSummaryGet,
  useLedgerPossessionDataV1GovLedgerPossessionGet,
  usePermissionDataV1GovPermissionGet,
} from '~/libs/apis/data/gov/gov'
import { isNotNil } from '@toss/utils'
import {
  useGetLwhPrdMovementDataV1BuildingsLwhRaIdPrdMovementGet,
  useLwhDetailDataV1BuildingsLwhRaIdGet,
  useLwhLeaseMarketTrendDataV1BuildingsLwhLeaseMarketTrendGet,
  useLwhLesseeChangeDataV1BuildingsLwhRaIdLesseeChangeGet,
  useLwhLesseeDataV1BuildingsLwhLesseeGet,
  useLwhTradeDataV1BuildingsLwhTradeGet,
} from '~/libs/apis/data/buildings-lwh/buildings-lwh'
import useLwhStackingPlan from '~/templates/common/view/stacking-plan/useLwhStackingPlan'
import { useDetailInfo } from '~/templates/DetailTypeProvider'
import {
  useGetRegistrationBuildingInfoDataV1RegistrationsBuildingInfoGet,
  useGetRegistrationsByPnuDataV1RegistrationsByPnuPnuGet,
} from '~/libs/apis/data/registrations/registrations'

const pageParam = { pageNum: 1, pageSize: 5 }

const useLwhFullDetailLoading = (raId: string) => {
  const isFull = useDetailInfo().detailType === 'full'
  const { lastUpdate } = useLastUpdateDate()

  const pastFiveYearPeriod = {
    minYear: (+lastUpdate.year - 5 + 1).toString(),
    minQuarter: lastUpdate.quarter,
    maxYear: lastUpdate.year,
    maxQuarter: lastUpdate.quarter,
  }

  // 건물
  const { data: detail, isLoading: isDetailLoading } = useLwhDetailDataV1BuildingsLwhRaIdGet(raId)
  const pnu = detail?.pnu
  const hasPnu = isNotNil(pnu)

  const { data: legderOutline, isLoading: isLedgerOutlineLoading } = useLedgerOutlineDataV1GovLedgerOutlineGet(
    { pnu: pnu! },
    {
      swr: {
        enabled: hasPnu && isFull,
      },
    },
  )
  const ledgerMgmBldrgstPk = legderOutline?.data?.[0]?.mgmBldrgstPk
  const ledgerInfoParams = { mgmBldrgstPk: ledgerMgmBldrgstPk!, pageNum: 1, pageSize: 10 }
  const { isLoading: isLedgerOutlineSummaryLoading } = useLedgerOutlineSummaryDataV1GovLedgerOutlineSummaryGet(
    { pnu: pnu! },
    { swr: { enabled: hasPnu && isFull, shouldRetryOnError: false } },
  )
  const { isLoading: isLedgerPossessionLoading } = useLedgerPossessionDataV1GovLedgerPossessionGet(ledgerInfoParams, {
    swr: {
      enabled: isNotNil(ledgerMgmBldrgstPk) && isFull,
    },
  })
  const { isLoading: isLedgerFloorLoading } = useLedgerFloorDataV1GovLedgerFloorGet(ledgerInfoParams, {
    swr: {
      enabled: isNotNil(ledgerMgmBldrgstPk) && isFull,
    },
  })
  const { isLoading: isPermissionLoading } = usePermissionDataV1GovPermissionGet(
    { pnu: pnu!, ...pageParam },
    {
      swr: {
        enabled: hasPnu && isFull,
      },
    },
  )
  const { isLoading: isDemolishLoading } = useDemolishDataV1GovDemolishGet(
    { pnu: pnu!, pageNum: 1, pageSize: 10 },
    {
      swr: {
        enabled: hasPnu && isFull,
      },
    },
  )

  // 토지
  const { data: lands, isLoading: isLandLoading } = useLandInfoDataV1GovLandGet({ raId })
  const landsPnu = lands?.at(0)?.pnu
  const { isLoading: isLandUsePlanLoading } = useGetLandUsePlanDataV1GovLandUsePlanGet(
    { pnu: landsPnu! },
    {
      swr: {
        enabled: isNotNil(landsPnu),
      },
    },
  )
  const { isLoading: isLandChangeOwnerLoading } = useLandChangeOwnerDataV1GovLandChangeOwnerGet(
    { pnu: landsPnu!, ...pageParam },
    {
      swr: {
        enabled: isNotNil(landsPnu),
      },
    },
  )
  const { isLoading: isLandPriceChartLoading } = useLandPriceDataV1GovLandPriceGet(
    {
      pnu: landsPnu!,
    },
    {
      swr: {
        enabled: isNotNil(landsPnu) && isFull,
      },
    },
  )

  // 임대차
  const { isLoading: isLeaseMarketTrendLoading } = useLwhLeaseMarketTrendDataV1BuildingsLwhLeaseMarketTrendGet({
    ...pastFiveYearPeriod,
    raId,
    valueType: 'rentFee',
  })
  const {
    dongResponse: { isLoading: isDongLoading },
    stackingPlanResponse: { isLoading: isStackingPlanLoading },
  } = useLwhStackingPlan({
    raId,
    year: lastUpdate.year,
    quarter: lastUpdate.quarter,
    enabled: isFull,
  })
  const { isLoading: isLesseeChangeLoading } = useLwhLesseeChangeDataV1BuildingsLwhRaIdLesseeChangeGet(
    raId,
    {
      year: lastUpdate.year,
      quarter: lastUpdate.quarter,
    },
    { swr: { enabled: isFull } },
  )
  const { isLoading: isLesseeLoading } = useLwhLesseeDataV1BuildingsLwhLesseeGet(
    { raId, ...pageParam, year: lastUpdate.year, quarter: lastUpdate.quarter },
    {
      swr: { keepPreviousData: true },
    },
  )

  // 거래
  const { isLoading: isTradeLoading } = useLwhTradeDataV1BuildingsLwhTradeGet({
    raId: raId,
    ...pageParam,
  })

  // 등기
  const { isLoading: isRegistrationsLoading } = useGetRegistrationsByPnuDataV1RegistrationsByPnuPnuGet(pnu!, {
    swr: { enabled: Boolean(pnu), shouldRetryOnError: false },
  })
  const { isLoading: isBuildingLoading } = useGetRegistrationBuildingInfoDataV1RegistrationsBuildingInfoGet(
    { pnu: pnu! },
    { swr: { enabled: hasPnu } },
  )

  const { isLoading: isPrdMovementLoading } = useGetLwhPrdMovementDataV1BuildingsLwhRaIdPrdMovementGet(
    raId,
    {},
    {
      swr: { enabled: isNotNil(raId) },
    },
  )

  return (
    isDetailLoading ||
    isLandLoading ||
    isLedgerOutlineLoading ||
    isLedgerOutlineSummaryLoading ||
    isLedgerPossessionLoading ||
    isLedgerFloorLoading ||
    isPermissionLoading ||
    isDemolishLoading ||
    isLandUsePlanLoading ||
    isLandChangeOwnerLoading ||
    isLandPriceChartLoading ||
    isLeaseMarketTrendLoading ||
    isDongLoading ||
    isLesseeChangeLoading ||
    isStackingPlanLoading ||
    isLesseeLoading ||
    isTradeLoading ||
    isRegistrationsLoading ||
    isBuildingLoading ||
    isPrdMovementLoading
  )
}

export default useLwhFullDetailLoading
