import { x } from '@xstyled/styled-components'
import { useTranslation } from 'next-i18next'
import { forwardRef, ReactNode } from 'react'
import CloseIcon from '~/assets/images/icons/x-graphic.svg'
import Box from '~/components/Box/Box'
import Popover, { PopoverProps } from '~/components/Dropdown/Popover'
import { PopperPosition } from '~/components/Dropdown/Popper'
import Typography from '~/components/Typography/Typography'
import { MAP_LAYER_Z_INDEX } from '~/libs/constants/common'
import useMapType from '~/libs/hooks/useMapType'
import useBuildingsFilterCount from '~/templates/place/hooks/useBuildingsFilterCount'
import useLandsFilterCount from '~/templates/place/hooks/useLandsFilterCount'
import useLeaseProductsFilterCount from '~/templates/topic/lease-product/hooks/useLeaseProductsFilterCount'
import useRegistrationsFilterCount from '~/templates/topic/registration/hooks/useRegistrationsFilterCount'
import useTenantsFilterCount from '~/templates/topic/tenant/hooks/useTenantsFilterCount'

interface FilterOverlayProps {
  popoverProps: PopoverProps
  children: ReactNode
  categoryTab?: ReactNode
  onReset?: () => void
  onScroll?: (refName: string) => void
  type?: 'FILTER' | 'MY'
}

const FilterOverlay = forwardRef<HTMLDivElement, FilterOverlayProps>(
  ({ popoverProps, children, categoryTab, type = 'FILTER' }, ref) => {
    const { t } = useTranslation('common', { keyPrefix: 'common_term' })
    const { t: place } = useTranslation('place')
    const { filterType } = useMapType()
    const useFilterCount = {
      building: useBuildingsFilterCount(),
      land: useLandsFilterCount(),
      tenant: useTenantsFilterCount(),
      registration: useRegistrationsFilterCount(),
      leaseProduct: useLeaseProductsFilterCount(),
    }
    const { onClose, ...rest } = popoverProps
    const filterCount = useFilterCount[filterType]
    const getPosition = (): PopperPosition => {
      return {
        top: 128,
        right: 14,
      }
    }

    return (
      <Popover gutter={false} scroll={false} {...rest} getPosition={getPosition} zIndex={MAP_LAYER_Z_INDEX.FILTER}>
        <Box ref={ref} display="flex" flexDirection="column" boxShadow="black-03" zIndex={1}>
          <Box display="flex" px={4} pt={3.5} pb={3} justifyContent="space-between">
            {type === 'FILTER' ? (
              <>
                <Box display="flex" alignItems="center" gap={1}>
                  <Typography variant="subtitle" color="gray-800" fontWeight="bold">
                    {t('filter')}
                  </Typography>
                  <Typography variant="subtitle" color="purple-700" fontWeight="bold">
                    {filterCount}
                  </Typography>
                </Box>
                <Box display="flex">
                  <x.button backgroundColor="transparent" outline={{ focus: 'none' }} onClick={onClose}>
                    <CloseIcon width="14px" height="14px" color="var(--color-gray-800)" />
                  </x.button>
                </Box>
              </>
            ) : (
              <>
                <Box display="flex" justifyContent="flex-start" alignItems="center" gap="8px">
                  <Typography variant="subtitle" fontWeight="bold" color="gray-800">
                    {place('place_term.my_filters')}
                  </Typography>
                  <Typography variant="caption2" color="gray-700">
                    {place('place_msg.my_filters_guide')}
                  </Typography>
                </Box>
                <x.button backgroundColor="transparent" outline={{ focus: 'none' }} onClick={onClose}>
                  <CloseIcon width="14px" height="14px" color="var(--color-gray-800)" />
                </x.button>
              </>
            )}
          </Box>
          {categoryTab}
        </Box>
        {children}
      </Popover>
    )
  },
)

FilterOverlay.displayName = 'FilterOverlay'

export default FilterOverlay
