/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * RA backend
 * OpenAPI spec version: 1.8.1
 */
import useSwr from 'swr'
import type {
  Key,
  SWRConfiguration
} from 'swr'
import type {
  BuildingsOfficeLesseeChangeOut
} from '../model/buildingsOfficeLesseeChangeOut'
import type {
  GetOfficePrdInfoDataV1BuildingsOfficeRaIdPrdInfoGetParams
} from '../model/getOfficePrdInfoDataV1BuildingsOfficeRaIdPrdInfoGetParams'
import type {
  GetOfficePrdMovementDataV1BuildingsOfficeRaIdPrdMovementGetParams
} from '../model/getOfficePrdMovementDataV1BuildingsOfficeRaIdPrdMovementGetParams'
import type {
  GetOfficeStackingPlanDongDataV1BuildingsOfficeRaIdStackingPlanDongGetParams
} from '../model/getOfficeStackingPlanDongDataV1BuildingsOfficeRaIdStackingPlanDongGetParams'
import type {
  HTTPValidationError
} from '../model/hTTPValidationError'
import type {
  LeaseStatusOut
} from '../model/leaseStatusOut'
import type {
  NearScheduledSupplyPagingOut
} from '../model/nearScheduledSupplyPagingOut'
import type {
  NearTradePagingOut
} from '../model/nearTradePagingOut'
import type {
  OfficeDetailListDataV1BuildingsOfficeGetParams
} from '../model/officeDetailListDataV1BuildingsOfficeGetParams'
import type {
  OfficeLeaseMarketTrendDataV1BuildingsOfficeLeaseMarketTrendGetParams
} from '../model/officeLeaseMarketTrendDataV1BuildingsOfficeLeaseMarketTrendGetParams'
import type {
  OfficeLeaseStatusBoeDataV1BuildingsOfficeLeaseStatusBoeGetParams
} from '../model/officeLeaseStatusBoeDataV1BuildingsOfficeLeaseStatusBoeGetParams'
import type {
  OfficeLeaseTrendDataV1BuildingsOfficeLeaseTrendGetParams
} from '../model/officeLeaseTrendDataV1BuildingsOfficeLeaseTrendGetParams'
import type {
  OfficeLeaseTrendExcelDataV1BuildingsOfficeLeaseTrendExcelGetParams
} from '../model/officeLeaseTrendExcelDataV1BuildingsOfficeLeaseTrendExcelGetParams'
import type {
  OfficeLeaseTrendOut
} from '../model/officeLeaseTrendOut'
import type {
  OfficeLesseeChangeDataV1BuildingsOfficeRaIdLesseeChangeGetParams
} from '../model/officeLesseeChangeDataV1BuildingsOfficeRaIdLesseeChangeGetParams'
import type {
  OfficeLesseeDataV1BuildingsOfficeLesseeGetParams
} from '../model/officeLesseeDataV1BuildingsOfficeLesseeGetParams'
import type {
  OfficeLesseeStatusDataV1BuildingsOfficeRaIdLesseeStatusGetParams
} from '../model/officeLesseeStatusDataV1BuildingsOfficeRaIdLesseeStatusGetParams'
import type {
  OfficeLesseeStatusOut
} from '../model/officeLesseeStatusOut'
import type {
  OfficeNearLeaseDataV1BuildingsOfficeRaIdNearbyLeaseGetParams
} from '../model/officeNearLeaseDataV1BuildingsOfficeRaIdNearbyLeaseGetParams'
import type {
  OfficeNearLeasePagingOut
} from '../model/officeNearLeasePagingOut'
import type {
  OfficeNearScheduledSupplyDataV1BuildingsOfficeRaIdNearbyScheduledSupplyGetParams
} from '../model/officeNearScheduledSupplyDataV1BuildingsOfficeRaIdNearbyScheduledSupplyGetParams'
import type {
  OfficeNearTradeDataV1BuildingsOfficeRaIdNearbyTradeGetParams
} from '../model/officeNearTradeDataV1BuildingsOfficeRaIdNearbyTradeGetParams'
import type {
  OfficeOut
} from '../model/officeOut'
import type {
  OfficePrdInfoOut
} from '../model/officePrdInfoOut'
import type {
  OfficePrdMovementPagingOut
} from '../model/officePrdMovementPagingOut'
import type {
  OfficeSimilarBuildingDataV1BuildingsOfficeRaIdSimilarGetParams
} from '../model/officeSimilarBuildingDataV1BuildingsOfficeRaIdSimilarGetParams'
import type {
  OfficeStackingPlanDataV1BuildingsOfficeRaIdStackingPlanGetParams
} from '../model/officeStackingPlanDataV1BuildingsOfficeRaIdStackingPlanGetParams'
import type {
  OfficeStackingPlanDongOut
} from '../model/officeStackingPlanDongOut'
import type {
  OfficeStackingPlanOut
} from '../model/officeStackingPlanOut'
import type {
  OfficeSupplyDataV1BuildingsOfficeSupplyGetParams
} from '../model/officeSupplyDataV1BuildingsOfficeSupplyGetParams'
import type {
  OfficeTradeDataV1BuildingsOfficeTradeGetParams
} from '../model/officeTradeDataV1BuildingsOfficeTradeGetParams'
import type {
  RaIdBase
} from '../model/raIdBase'
import type {
  SchemasBuildingsOfficeLeaseMarketTrendOut
} from '../model/schemasBuildingsOfficeLeaseMarketTrendOut'
import type {
  SchemasBuildingsOfficeLesseePagingOut
} from '../model/schemasBuildingsOfficeLesseePagingOut'
import type {
  SchemasBuildingsOfficeTradePagingOut
} from '../model/schemasBuildingsOfficeTradePagingOut'
import type {
  SupplyPagingOut
} from '../model/supplyPagingOut'
import { raApiCall } from '../../../utils/customInstance';
import { customFormData } from '../../../utils/customFormData';


  
  // eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

/**
 * @summary 오피스 기본정보 리스트(사용 테이블: ra_v2.mv_buildings_office_detail)
 */
export const officeDetailListDataV1BuildingsOfficeGet = (
    params: OfficeDetailListDataV1BuildingsOfficeGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<OfficeOut[]>(
      {url: `/data/v1/buildings/office`, method: 'GET',
        params
    },
      options);
    }
  

export const getOfficeDetailListDataV1BuildingsOfficeGetKey = (params: OfficeDetailListDataV1BuildingsOfficeGetParams,) => [`/data/v1/buildings/office`, ...(params ? [params]: [])] as const;

    
export type OfficeDetailListDataV1BuildingsOfficeGetQueryResult = NonNullable<Awaited<ReturnType<typeof officeDetailListDataV1BuildingsOfficeGet>>>
export type OfficeDetailListDataV1BuildingsOfficeGetQueryError = HTTPValidationError

/**
 * @summary 오피스 기본정보 리스트(사용 테이블: ra_v2.mv_buildings_office_detail)
 */
export const useOfficeDetailListDataV1BuildingsOfficeGet = <TError = HTTPValidationError>(
 params: OfficeDetailListDataV1BuildingsOfficeGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof officeDetailListDataV1BuildingsOfficeGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getOfficeDetailListDataV1BuildingsOfficeGetKey(params) : null);
  const swrFn = () => officeDetailListDataV1BuildingsOfficeGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 오피스 임대 시세(사용 테이블: ra_v2.mv_buildings_lease_trend)
 */
export const officeLeaseStatusBoeDataV1BuildingsOfficeLeaseStatusBoeGet = (
    params: OfficeLeaseStatusBoeDataV1BuildingsOfficeLeaseStatusBoeGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<LeaseStatusOut>(
      {url: `/data/v1/buildings/office/lease/status/boe`, method: 'GET',
        params
    },
      options);
    }
  

export const getOfficeLeaseStatusBoeDataV1BuildingsOfficeLeaseStatusBoeGetKey = (params: OfficeLeaseStatusBoeDataV1BuildingsOfficeLeaseStatusBoeGetParams,) => [`/data/v1/buildings/office/lease/status/boe`, ...(params ? [params]: [])] as const;

    
export type OfficeLeaseStatusBoeDataV1BuildingsOfficeLeaseStatusBoeGetQueryResult = NonNullable<Awaited<ReturnType<typeof officeLeaseStatusBoeDataV1BuildingsOfficeLeaseStatusBoeGet>>>
export type OfficeLeaseStatusBoeDataV1BuildingsOfficeLeaseStatusBoeGetQueryError = HTTPValidationError

/**
 * @summary 오피스 임대 시세(사용 테이블: ra_v2.mv_buildings_lease_trend)
 */
export const useOfficeLeaseStatusBoeDataV1BuildingsOfficeLeaseStatusBoeGet = <TError = HTTPValidationError>(
 params: OfficeLeaseStatusBoeDataV1BuildingsOfficeLeaseStatusBoeGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof officeLeaseStatusBoeDataV1BuildingsOfficeLeaseStatusBoeGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getOfficeLeaseStatusBoeDataV1BuildingsOfficeLeaseStatusBoeGetKey(params) : null);
  const swrFn = () => officeLeaseStatusBoeDataV1BuildingsOfficeLeaseStatusBoeGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 오피스 임대 시세 추이(사용 테이블: ra_v2.mv_buildings_lease_trend)
 */
export const officeLeaseTrendDataV1BuildingsOfficeLeaseTrendGet = (
    params: OfficeLeaseTrendDataV1BuildingsOfficeLeaseTrendGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<OfficeLeaseTrendOut[]>(
      {url: `/data/v1/buildings/office/lease/trend`, method: 'GET',
        params
    },
      options);
    }
  

export const getOfficeLeaseTrendDataV1BuildingsOfficeLeaseTrendGetKey = (params: OfficeLeaseTrendDataV1BuildingsOfficeLeaseTrendGetParams,) => [`/data/v1/buildings/office/lease/trend`, ...(params ? [params]: [])] as const;

    
export type OfficeLeaseTrendDataV1BuildingsOfficeLeaseTrendGetQueryResult = NonNullable<Awaited<ReturnType<typeof officeLeaseTrendDataV1BuildingsOfficeLeaseTrendGet>>>
export type OfficeLeaseTrendDataV1BuildingsOfficeLeaseTrendGetQueryError = HTTPValidationError

/**
 * @summary 오피스 임대 시세 추이(사용 테이블: ra_v2.mv_buildings_lease_trend)
 */
export const useOfficeLeaseTrendDataV1BuildingsOfficeLeaseTrendGet = <TError = HTTPValidationError>(
 params: OfficeLeaseTrendDataV1BuildingsOfficeLeaseTrendGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof officeLeaseTrendDataV1BuildingsOfficeLeaseTrendGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getOfficeLeaseTrendDataV1BuildingsOfficeLeaseTrendGetKey(params) : null);
  const swrFn = () => officeLeaseTrendDataV1BuildingsOfficeLeaseTrendGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 오피스 건물/권역 임대 시세 추이(사용 테이블: ra_v2.mv_buildings_lease_trend)
 */
export const officeLeaseTrendExcelDataV1BuildingsOfficeLeaseTrendExcelGet = (
    params: OfficeLeaseTrendExcelDataV1BuildingsOfficeLeaseTrendExcelGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<unknown>(
      {url: `/data/v1/buildings/office/lease/trend/excel`, method: 'GET',
        params
    },
      options);
    }
  

export const getOfficeLeaseTrendExcelDataV1BuildingsOfficeLeaseTrendExcelGetKey = (params: OfficeLeaseTrendExcelDataV1BuildingsOfficeLeaseTrendExcelGetParams,) => [`/data/v1/buildings/office/lease/trend/excel`, ...(params ? [params]: [])] as const;

    
export type OfficeLeaseTrendExcelDataV1BuildingsOfficeLeaseTrendExcelGetQueryResult = NonNullable<Awaited<ReturnType<typeof officeLeaseTrendExcelDataV1BuildingsOfficeLeaseTrendExcelGet>>>
export type OfficeLeaseTrendExcelDataV1BuildingsOfficeLeaseTrendExcelGetQueryError = HTTPValidationError

/**
 * @summary 오피스 건물/권역 임대 시세 추이(사용 테이블: ra_v2.mv_buildings_lease_trend)
 */
export const useOfficeLeaseTrendExcelDataV1BuildingsOfficeLeaseTrendExcelGet = <TError = HTTPValidationError>(
 params: OfficeLeaseTrendExcelDataV1BuildingsOfficeLeaseTrendExcelGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof officeLeaseTrendExcelDataV1BuildingsOfficeLeaseTrendExcelGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getOfficeLeaseTrendExcelDataV1BuildingsOfficeLeaseTrendExcelGetKey(params) : null);
  const swrFn = () => officeLeaseTrendExcelDataV1BuildingsOfficeLeaseTrendExcelGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 오피스 마켓 임대 시세 추이(사용 테이블: ra_v2.mv_buildings_lease_trend)
 */
export const officeLeaseMarketTrendDataV1BuildingsOfficeLeaseMarketTrendGet = (
    params: OfficeLeaseMarketTrendDataV1BuildingsOfficeLeaseMarketTrendGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<SchemasBuildingsOfficeLeaseMarketTrendOut[]>(
      {url: `/data/v1/buildings/office/lease/market/trend`, method: 'GET',
        params
    },
      options);
    }
  

export const getOfficeLeaseMarketTrendDataV1BuildingsOfficeLeaseMarketTrendGetKey = (params: OfficeLeaseMarketTrendDataV1BuildingsOfficeLeaseMarketTrendGetParams,) => [`/data/v1/buildings/office/lease/market/trend`, ...(params ? [params]: [])] as const;

    
export type OfficeLeaseMarketTrendDataV1BuildingsOfficeLeaseMarketTrendGetQueryResult = NonNullable<Awaited<ReturnType<typeof officeLeaseMarketTrendDataV1BuildingsOfficeLeaseMarketTrendGet>>>
export type OfficeLeaseMarketTrendDataV1BuildingsOfficeLeaseMarketTrendGetQueryError = HTTPValidationError

/**
 * @summary 오피스 마켓 임대 시세 추이(사용 테이블: ra_v2.mv_buildings_lease_trend)
 */
export const useOfficeLeaseMarketTrendDataV1BuildingsOfficeLeaseMarketTrendGet = <TError = HTTPValidationError>(
 params: OfficeLeaseMarketTrendDataV1BuildingsOfficeLeaseMarketTrendGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof officeLeaseMarketTrendDataV1BuildingsOfficeLeaseMarketTrendGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getOfficeLeaseMarketTrendDataV1BuildingsOfficeLeaseMarketTrendGetKey(params) : null);
  const swrFn = () => officeLeaseMarketTrendDataV1BuildingsOfficeLeaseMarketTrendGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 오피스 임차인(사용 테이블: ra_v2.mv_building_office_tenant_from_rtb)
 */
export const officeLesseeDataV1BuildingsOfficeLesseeGet = (
    params: OfficeLesseeDataV1BuildingsOfficeLesseeGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<SchemasBuildingsOfficeLesseePagingOut>(
      {url: `/data/v1/buildings/office/lessee`, method: 'GET',
        params
    },
      options);
    }
  

export const getOfficeLesseeDataV1BuildingsOfficeLesseeGetKey = (params: OfficeLesseeDataV1BuildingsOfficeLesseeGetParams,) => [`/data/v1/buildings/office/lessee`, ...(params ? [params]: [])] as const;

    
export type OfficeLesseeDataV1BuildingsOfficeLesseeGetQueryResult = NonNullable<Awaited<ReturnType<typeof officeLesseeDataV1BuildingsOfficeLesseeGet>>>
export type OfficeLesseeDataV1BuildingsOfficeLesseeGetQueryError = HTTPValidationError

/**
 * @summary 오피스 임차인(사용 테이블: ra_v2.mv_building_office_tenant_from_rtb)
 */
export const useOfficeLesseeDataV1BuildingsOfficeLesseeGet = <TError = HTTPValidationError>(
 params: OfficeLesseeDataV1BuildingsOfficeLesseeGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof officeLesseeDataV1BuildingsOfficeLesseeGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getOfficeLesseeDataV1BuildingsOfficeLesseeGetKey(params) : null);
  const swrFn = () => officeLesseeDataV1BuildingsOfficeLesseeGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 오피스 거래내역(사용 테이블: ra_v2.mv_buildings_trade_trend)
 */
export const officeTradeDataV1BuildingsOfficeTradeGet = (
    params?: OfficeTradeDataV1BuildingsOfficeTradeGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<SchemasBuildingsOfficeTradePagingOut>(
      {url: `/data/v1/buildings/office/trade`, method: 'GET',
        params
    },
      options);
    }
  

export const getOfficeTradeDataV1BuildingsOfficeTradeGetKey = (params?: OfficeTradeDataV1BuildingsOfficeTradeGetParams,) => [`/data/v1/buildings/office/trade`, ...(params ? [params]: [])] as const;

    
export type OfficeTradeDataV1BuildingsOfficeTradeGetQueryResult = NonNullable<Awaited<ReturnType<typeof officeTradeDataV1BuildingsOfficeTradeGet>>>
export type OfficeTradeDataV1BuildingsOfficeTradeGetQueryError = HTTPValidationError

/**
 * @summary 오피스 거래내역(사용 테이블: ra_v2.mv_buildings_trade_trend)
 */
export const useOfficeTradeDataV1BuildingsOfficeTradeGet = <TError = HTTPValidationError>(
 params?: OfficeTradeDataV1BuildingsOfficeTradeGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof officeTradeDataV1BuildingsOfficeTradeGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getOfficeTradeDataV1BuildingsOfficeTradeGetKey(params) : null);
  const swrFn = () => officeTradeDataV1BuildingsOfficeTradeGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 오피스 공급내역(사용 테이블: ra_v2.mv_buildings_supply)
 */
export const officeSupplyDataV1BuildingsOfficeSupplyGet = (
    params: OfficeSupplyDataV1BuildingsOfficeSupplyGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<SupplyPagingOut>(
      {url: `/data/v1/buildings/office/supply`, method: 'GET',
        params
    },
      options);
    }
  

export const getOfficeSupplyDataV1BuildingsOfficeSupplyGetKey = (params: OfficeSupplyDataV1BuildingsOfficeSupplyGetParams,) => [`/data/v1/buildings/office/supply`, ...(params ? [params]: [])] as const;

    
export type OfficeSupplyDataV1BuildingsOfficeSupplyGetQueryResult = NonNullable<Awaited<ReturnType<typeof officeSupplyDataV1BuildingsOfficeSupplyGet>>>
export type OfficeSupplyDataV1BuildingsOfficeSupplyGetQueryError = HTTPValidationError

/**
 * @summary 오피스 공급내역(사용 테이블: ra_v2.mv_buildings_supply)
 */
export const useOfficeSupplyDataV1BuildingsOfficeSupplyGet = <TError = HTTPValidationError>(
 params: OfficeSupplyDataV1BuildingsOfficeSupplyGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof officeSupplyDataV1BuildingsOfficeSupplyGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getOfficeSupplyDataV1BuildingsOfficeSupplyGetKey(params) : null);
  const swrFn = () => officeSupplyDataV1BuildingsOfficeSupplyGet(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 오피스 기본정보(사용 테이블: ra_v2.mv_buildings_office_detail)
 */
export const officeDetailDataV1BuildingsOfficeRaIdGet = (
    raId: string,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<OfficeOut>(
      {url: `/data/v1/buildings/office/${raId}`, method: 'GET'
    },
      options);
    }
  

export const getOfficeDetailDataV1BuildingsOfficeRaIdGetKey = (raId: string,) => [`/data/v1/buildings/office/${raId}`] as const;

    
export type OfficeDetailDataV1BuildingsOfficeRaIdGetQueryResult = NonNullable<Awaited<ReturnType<typeof officeDetailDataV1BuildingsOfficeRaIdGet>>>
export type OfficeDetailDataV1BuildingsOfficeRaIdGetQueryError = HTTPValidationError

/**
 * @summary 오피스 기본정보(사용 테이블: ra_v2.mv_buildings_office_detail)
 */
export const useOfficeDetailDataV1BuildingsOfficeRaIdGet = <TError = HTTPValidationError>(
 raId: string, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof officeDetailDataV1BuildingsOfficeRaIdGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(raId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getOfficeDetailDataV1BuildingsOfficeRaIdGetKey(raId) : null);
  const swrFn = () => officeDetailDataV1BuildingsOfficeRaIdGet(raId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 오피스 주변분석 거래 리스트(사용 테이블: ra_v2.mv_buildings_trade_trend)
 */
export const officeNearTradeDataV1BuildingsOfficeRaIdNearbyTradeGet = (
    raId: string,
    params?: OfficeNearTradeDataV1BuildingsOfficeRaIdNearbyTradeGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<NearTradePagingOut>(
      {url: `/data/v1/buildings/office/${raId}/nearby/trade`, method: 'GET',
        params
    },
      options);
    }
  

export const getOfficeNearTradeDataV1BuildingsOfficeRaIdNearbyTradeGetKey = (raId: string,
    params?: OfficeNearTradeDataV1BuildingsOfficeRaIdNearbyTradeGetParams,) => [`/data/v1/buildings/office/${raId}/nearby/trade`, ...(params ? [params]: [])] as const;

    
export type OfficeNearTradeDataV1BuildingsOfficeRaIdNearbyTradeGetQueryResult = NonNullable<Awaited<ReturnType<typeof officeNearTradeDataV1BuildingsOfficeRaIdNearbyTradeGet>>>
export type OfficeNearTradeDataV1BuildingsOfficeRaIdNearbyTradeGetQueryError = HTTPValidationError

/**
 * @summary 오피스 주변분석 거래 리스트(사용 테이블: ra_v2.mv_buildings_trade_trend)
 */
export const useOfficeNearTradeDataV1BuildingsOfficeRaIdNearbyTradeGet = <TError = HTTPValidationError>(
 raId: string,
    params?: OfficeNearTradeDataV1BuildingsOfficeRaIdNearbyTradeGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof officeNearTradeDataV1BuildingsOfficeRaIdNearbyTradeGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(raId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getOfficeNearTradeDataV1BuildingsOfficeRaIdNearbyTradeGetKey(raId,params) : null);
  const swrFn = () => officeNearTradeDataV1BuildingsOfficeRaIdNearbyTradeGet(raId,params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 오피스 주변분석 임대 리스트(사용 테이블: ra_v2.mv_buildings_lease_trend)
 */
export const officeNearLeaseDataV1BuildingsOfficeRaIdNearbyLeaseGet = (
    raId: string,
    params: OfficeNearLeaseDataV1BuildingsOfficeRaIdNearbyLeaseGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<OfficeNearLeasePagingOut>(
      {url: `/data/v1/buildings/office/${raId}/nearby/lease`, method: 'GET',
        params
    },
      options);
    }
  

export const getOfficeNearLeaseDataV1BuildingsOfficeRaIdNearbyLeaseGetKey = (raId: string,
    params: OfficeNearLeaseDataV1BuildingsOfficeRaIdNearbyLeaseGetParams,) => [`/data/v1/buildings/office/${raId}/nearby/lease`, ...(params ? [params]: [])] as const;

    
export type OfficeNearLeaseDataV1BuildingsOfficeRaIdNearbyLeaseGetQueryResult = NonNullable<Awaited<ReturnType<typeof officeNearLeaseDataV1BuildingsOfficeRaIdNearbyLeaseGet>>>
export type OfficeNearLeaseDataV1BuildingsOfficeRaIdNearbyLeaseGetQueryError = HTTPValidationError

/**
 * @summary 오피스 주변분석 임대 리스트(사용 테이블: ra_v2.mv_buildings_lease_trend)
 */
export const useOfficeNearLeaseDataV1BuildingsOfficeRaIdNearbyLeaseGet = <TError = HTTPValidationError>(
 raId: string,
    params: OfficeNearLeaseDataV1BuildingsOfficeRaIdNearbyLeaseGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof officeNearLeaseDataV1BuildingsOfficeRaIdNearbyLeaseGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(raId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getOfficeNearLeaseDataV1BuildingsOfficeRaIdNearbyLeaseGetKey(raId,params) : null);
  const swrFn = () => officeNearLeaseDataV1BuildingsOfficeRaIdNearbyLeaseGet(raId,params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 오피스 주변분석 공급예정 리스트(사용 테이블: ra_v2.mv_buildings_supply)
 */
export const officeNearScheduledSupplyDataV1BuildingsOfficeRaIdNearbyScheduledSupplyGet = (
    raId: string,
    params?: OfficeNearScheduledSupplyDataV1BuildingsOfficeRaIdNearbyScheduledSupplyGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<NearScheduledSupplyPagingOut>(
      {url: `/data/v1/buildings/office/${raId}/nearby/scheduled-supply`, method: 'GET',
        params
    },
      options);
    }
  

export const getOfficeNearScheduledSupplyDataV1BuildingsOfficeRaIdNearbyScheduledSupplyGetKey = (raId: string,
    params?: OfficeNearScheduledSupplyDataV1BuildingsOfficeRaIdNearbyScheduledSupplyGetParams,) => [`/data/v1/buildings/office/${raId}/nearby/scheduled-supply`, ...(params ? [params]: [])] as const;

    
export type OfficeNearScheduledSupplyDataV1BuildingsOfficeRaIdNearbyScheduledSupplyGetQueryResult = NonNullable<Awaited<ReturnType<typeof officeNearScheduledSupplyDataV1BuildingsOfficeRaIdNearbyScheduledSupplyGet>>>
export type OfficeNearScheduledSupplyDataV1BuildingsOfficeRaIdNearbyScheduledSupplyGetQueryError = HTTPValidationError

/**
 * @summary 오피스 주변분석 공급예정 리스트(사용 테이블: ra_v2.mv_buildings_supply)
 */
export const useOfficeNearScheduledSupplyDataV1BuildingsOfficeRaIdNearbyScheduledSupplyGet = <TError = HTTPValidationError>(
 raId: string,
    params?: OfficeNearScheduledSupplyDataV1BuildingsOfficeRaIdNearbyScheduledSupplyGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof officeNearScheduledSupplyDataV1BuildingsOfficeRaIdNearbyScheduledSupplyGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(raId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getOfficeNearScheduledSupplyDataV1BuildingsOfficeRaIdNearbyScheduledSupplyGetKey(raId,params) : null);
  const swrFn = () => officeNearScheduledSupplyDataV1BuildingsOfficeRaIdNearbyScheduledSupplyGet(raId,params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 오피스 유사건물(사용 테이블: ra_v2.dw_f_similar_building)
 */
export const officeSimilarBuildingDataV1BuildingsOfficeRaIdSimilarGet = (
    raId: string,
    params?: OfficeSimilarBuildingDataV1BuildingsOfficeRaIdSimilarGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<RaIdBase[]>(
      {url: `/data/v1/buildings/office/${raId}/similar`, method: 'GET',
        params
    },
      options);
    }
  

export const getOfficeSimilarBuildingDataV1BuildingsOfficeRaIdSimilarGetKey = (raId: string,
    params?: OfficeSimilarBuildingDataV1BuildingsOfficeRaIdSimilarGetParams,) => [`/data/v1/buildings/office/${raId}/similar`, ...(params ? [params]: [])] as const;

    
export type OfficeSimilarBuildingDataV1BuildingsOfficeRaIdSimilarGetQueryResult = NonNullable<Awaited<ReturnType<typeof officeSimilarBuildingDataV1BuildingsOfficeRaIdSimilarGet>>>
export type OfficeSimilarBuildingDataV1BuildingsOfficeRaIdSimilarGetQueryError = HTTPValidationError

/**
 * @summary 오피스 유사건물(사용 테이블: ra_v2.dw_f_similar_building)
 */
export const useOfficeSimilarBuildingDataV1BuildingsOfficeRaIdSimilarGet = <TError = HTTPValidationError>(
 raId: string,
    params?: OfficeSimilarBuildingDataV1BuildingsOfficeRaIdSimilarGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof officeSimilarBuildingDataV1BuildingsOfficeRaIdSimilarGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(raId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getOfficeSimilarBuildingDataV1BuildingsOfficeRaIdSimilarGetKey(raId,params) : null);
  const swrFn = () => officeSimilarBuildingDataV1BuildingsOfficeRaIdSimilarGet(raId,params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 오피스 임차인 현황(사용 테이블: ra_v2.mv_building_office_tenant_from_rtb)
 */
export const officeLesseeStatusDataV1BuildingsOfficeRaIdLesseeStatusGet = (
    raId: string,
    params: OfficeLesseeStatusDataV1BuildingsOfficeRaIdLesseeStatusGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<OfficeLesseeStatusOut[]>(
      {url: `/data/v1/buildings/office/${raId}/lessee/status`, method: 'GET',
        params
    },
      options);
    }
  

export const getOfficeLesseeStatusDataV1BuildingsOfficeRaIdLesseeStatusGetKey = (raId: string,
    params: OfficeLesseeStatusDataV1BuildingsOfficeRaIdLesseeStatusGetParams,) => [`/data/v1/buildings/office/${raId}/lessee/status`, ...(params ? [params]: [])] as const;

    
export type OfficeLesseeStatusDataV1BuildingsOfficeRaIdLesseeStatusGetQueryResult = NonNullable<Awaited<ReturnType<typeof officeLesseeStatusDataV1BuildingsOfficeRaIdLesseeStatusGet>>>
export type OfficeLesseeStatusDataV1BuildingsOfficeRaIdLesseeStatusGetQueryError = HTTPValidationError

/**
 * @summary 오피스 임차인 현황(사용 테이블: ra_v2.mv_building_office_tenant_from_rtb)
 */
export const useOfficeLesseeStatusDataV1BuildingsOfficeRaIdLesseeStatusGet = <TError = HTTPValidationError>(
 raId: string,
    params: OfficeLesseeStatusDataV1BuildingsOfficeRaIdLesseeStatusGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof officeLesseeStatusDataV1BuildingsOfficeRaIdLesseeStatusGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(raId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getOfficeLesseeStatusDataV1BuildingsOfficeRaIdLesseeStatusGetKey(raId,params) : null);
  const swrFn = () => officeLesseeStatusDataV1BuildingsOfficeRaIdLesseeStatusGet(raId,params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 오피스 임차인 변동(사용 테이블: ra_v2.mv_building_office_tenant_from_rtb)
 */
export const officeLesseeChangeDataV1BuildingsOfficeRaIdLesseeChangeGet = (
    raId: string,
    params: OfficeLesseeChangeDataV1BuildingsOfficeRaIdLesseeChangeGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<BuildingsOfficeLesseeChangeOut>(
      {url: `/data/v1/buildings/office/${raId}/lessee/change`, method: 'GET',
        params
    },
      options);
    }
  

export const getOfficeLesseeChangeDataV1BuildingsOfficeRaIdLesseeChangeGetKey = (raId: string,
    params: OfficeLesseeChangeDataV1BuildingsOfficeRaIdLesseeChangeGetParams,) => [`/data/v1/buildings/office/${raId}/lessee/change`, ...(params ? [params]: [])] as const;

    
export type OfficeLesseeChangeDataV1BuildingsOfficeRaIdLesseeChangeGetQueryResult = NonNullable<Awaited<ReturnType<typeof officeLesseeChangeDataV1BuildingsOfficeRaIdLesseeChangeGet>>>
export type OfficeLesseeChangeDataV1BuildingsOfficeRaIdLesseeChangeGetQueryError = HTTPValidationError

/**
 * @summary 오피스 임차인 변동(사용 테이블: ra_v2.mv_building_office_tenant_from_rtb)
 */
export const useOfficeLesseeChangeDataV1BuildingsOfficeRaIdLesseeChangeGet = <TError = HTTPValidationError>(
 raId: string,
    params: OfficeLesseeChangeDataV1BuildingsOfficeRaIdLesseeChangeGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof officeLesseeChangeDataV1BuildingsOfficeRaIdLesseeChangeGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(raId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getOfficeLesseeChangeDataV1BuildingsOfficeRaIdLesseeChangeGetKey(raId,params) : null);
  const swrFn = () => officeLesseeChangeDataV1BuildingsOfficeRaIdLesseeChangeGet(raId,params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 오피스 스태킹플랜(사용 테이블: ra_v2.mv_building_office_tenant_from_rtb)
 */
export const officeStackingPlanDataV1BuildingsOfficeRaIdStackingPlanGet = (
    raId: string,
    params: OfficeStackingPlanDataV1BuildingsOfficeRaIdStackingPlanGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<OfficeStackingPlanOut[]>(
      {url: `/data/v1/buildings/office/${raId}/stacking-plan`, method: 'GET',
        params
    },
      options);
    }
  

export const getOfficeStackingPlanDataV1BuildingsOfficeRaIdStackingPlanGetKey = (raId: string,
    params: OfficeStackingPlanDataV1BuildingsOfficeRaIdStackingPlanGetParams,) => [`/data/v1/buildings/office/${raId}/stacking-plan`, ...(params ? [params]: [])] as const;

    
export type OfficeStackingPlanDataV1BuildingsOfficeRaIdStackingPlanGetQueryResult = NonNullable<Awaited<ReturnType<typeof officeStackingPlanDataV1BuildingsOfficeRaIdStackingPlanGet>>>
export type OfficeStackingPlanDataV1BuildingsOfficeRaIdStackingPlanGetQueryError = HTTPValidationError

/**
 * @summary 오피스 스태킹플랜(사용 테이블: ra_v2.mv_building_office_tenant_from_rtb)
 */
export const useOfficeStackingPlanDataV1BuildingsOfficeRaIdStackingPlanGet = <TError = HTTPValidationError>(
 raId: string,
    params: OfficeStackingPlanDataV1BuildingsOfficeRaIdStackingPlanGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof officeStackingPlanDataV1BuildingsOfficeRaIdStackingPlanGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(raId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getOfficeStackingPlanDataV1BuildingsOfficeRaIdStackingPlanGetKey(raId,params) : null);
  const swrFn = () => officeStackingPlanDataV1BuildingsOfficeRaIdStackingPlanGet(raId,params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 오피스 스태킹플랜 동 리스트 조회(사용 테이블: ra_v2.mv_building_office_tenant_from_rtb)
 */
export const getOfficeStackingPlanDongDataV1BuildingsOfficeRaIdStackingPlanDongGet = (
    raId: string,
    params: GetOfficeStackingPlanDongDataV1BuildingsOfficeRaIdStackingPlanDongGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<OfficeStackingPlanDongOut[]>(
      {url: `/data/v1/buildings/office/${raId}/stacking-plan/dong`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetOfficeStackingPlanDongDataV1BuildingsOfficeRaIdStackingPlanDongGetKey = (raId: string,
    params: GetOfficeStackingPlanDongDataV1BuildingsOfficeRaIdStackingPlanDongGetParams,) => [`/data/v1/buildings/office/${raId}/stacking-plan/dong`, ...(params ? [params]: [])] as const;

    
export type GetOfficeStackingPlanDongDataV1BuildingsOfficeRaIdStackingPlanDongGetQueryResult = NonNullable<Awaited<ReturnType<typeof getOfficeStackingPlanDongDataV1BuildingsOfficeRaIdStackingPlanDongGet>>>
export type GetOfficeStackingPlanDongDataV1BuildingsOfficeRaIdStackingPlanDongGetQueryError = HTTPValidationError

/**
 * @summary 오피스 스태킹플랜 동 리스트 조회(사용 테이블: ra_v2.mv_building_office_tenant_from_rtb)
 */
export const useGetOfficeStackingPlanDongDataV1BuildingsOfficeRaIdStackingPlanDongGet = <TError = HTTPValidationError>(
 raId: string,
    params: GetOfficeStackingPlanDongDataV1BuildingsOfficeRaIdStackingPlanDongGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getOfficeStackingPlanDongDataV1BuildingsOfficeRaIdStackingPlanDongGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(raId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetOfficeStackingPlanDongDataV1BuildingsOfficeRaIdStackingPlanDongGetKey(raId,params) : null);
  const swrFn = () => getOfficeStackingPlanDongDataV1BuildingsOfficeRaIdStackingPlanDongGet(raId,params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 오피스 임차계약 (사용 테이블: ra_v2.mv_buildings_lease_prd_info)
 */
export const getOfficePrdInfoDataV1BuildingsOfficeRaIdPrdInfoGet = (
    raId: string,
    params: GetOfficePrdInfoDataV1BuildingsOfficeRaIdPrdInfoGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<OfficePrdInfoOut[]>(
      {url: `/data/v1/buildings/office/${raId}/prd-info`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetOfficePrdInfoDataV1BuildingsOfficeRaIdPrdInfoGetKey = (raId: string,
    params: GetOfficePrdInfoDataV1BuildingsOfficeRaIdPrdInfoGetParams,) => [`/data/v1/buildings/office/${raId}/prd-info`, ...(params ? [params]: [])] as const;

    
export type GetOfficePrdInfoDataV1BuildingsOfficeRaIdPrdInfoGetQueryResult = NonNullable<Awaited<ReturnType<typeof getOfficePrdInfoDataV1BuildingsOfficeRaIdPrdInfoGet>>>
export type GetOfficePrdInfoDataV1BuildingsOfficeRaIdPrdInfoGetQueryError = HTTPValidationError

/**
 * @summary 오피스 임차계약 (사용 테이블: ra_v2.mv_buildings_lease_prd_info)
 */
export const useGetOfficePrdInfoDataV1BuildingsOfficeRaIdPrdInfoGet = <TError = HTTPValidationError>(
 raId: string,
    params: GetOfficePrdInfoDataV1BuildingsOfficeRaIdPrdInfoGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getOfficePrdInfoDataV1BuildingsOfficeRaIdPrdInfoGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(raId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetOfficePrdInfoDataV1BuildingsOfficeRaIdPrdInfoGetKey(raId,params) : null);
  const swrFn = () => getOfficePrdInfoDataV1BuildingsOfficeRaIdPrdInfoGet(raId,params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 오피스 임대매물 (사용 테이블: ra_v2.mv_lease_products)
 */
export const getOfficePrdMovementDataV1BuildingsOfficeRaIdPrdMovementGet = (
    raId: string,
    params?: GetOfficePrdMovementDataV1BuildingsOfficeRaIdPrdMovementGetParams,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<OfficePrdMovementPagingOut>(
      {url: `/data/v1/buildings/office/${raId}/prd-movement`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetOfficePrdMovementDataV1BuildingsOfficeRaIdPrdMovementGetKey = (raId: string,
    params?: GetOfficePrdMovementDataV1BuildingsOfficeRaIdPrdMovementGetParams,) => [`/data/v1/buildings/office/${raId}/prd-movement`, ...(params ? [params]: [])] as const;

    
export type GetOfficePrdMovementDataV1BuildingsOfficeRaIdPrdMovementGetQueryResult = NonNullable<Awaited<ReturnType<typeof getOfficePrdMovementDataV1BuildingsOfficeRaIdPrdMovementGet>>>
export type GetOfficePrdMovementDataV1BuildingsOfficeRaIdPrdMovementGetQueryError = HTTPValidationError

/**
 * @summary 오피스 임대매물 (사용 테이블: ra_v2.mv_lease_products)
 */
export const useGetOfficePrdMovementDataV1BuildingsOfficeRaIdPrdMovementGet = <TError = HTTPValidationError>(
 raId: string,
    params?: GetOfficePrdMovementDataV1BuildingsOfficeRaIdPrdMovementGetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getOfficePrdMovementDataV1BuildingsOfficeRaIdPrdMovementGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(raId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetOfficePrdMovementDataV1BuildingsOfficeRaIdPrdMovementGetKey(raId,params) : null);
  const swrFn = () => getOfficePrdMovementDataV1BuildingsOfficeRaIdPrdMovementGet(raId,params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

