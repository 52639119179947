/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * RA backend
 * OpenAPI spec version: 1.8.1
 */

export type Language = typeof Language[keyof typeof Language];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Language = {
  KOREAN: 'KOREAN',
  ENGLISH: 'ENGLISH',
} as const;
