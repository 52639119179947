import {
  BuildingsDataV1BuildingsGetParams,
  type BuildingsDetailsOut,
  GetBuildingsMarkerDataV1BuildingsMarkerGetParams,
  GetLandMarkerDataV1LandsMarkerGetParams,
  GetPermissionsMarkerDataV1LandsPermissionsAggregationsMarkerGetParams,
  LandDetailOut,
  LwhOut,
  OfficeOut,
} from '~/libs/apis/data/model'
import { millisecondsInSecond } from 'date-fns/constants'
import { PropertyDialogCardModel } from '~/templates/common/dialog/DataPropertyDialog'
import { NMapZoomLevel, Property } from '~/libs/constants/common'

export enum MapContentOption {
  'supply' = 'supply', // 공급
  'lease' = 'lease', // 임대
  'trade' = 'trade', // 거래
}

export const AreaLayerMaxZoomLevel: keyof typeof NMapZoomLevel = '100m'

type SearchParams = {
  raId: string
  pnu: string
  regionCode: string
  searchType: string
}

type NearByDataParams = 'nearbyRaId' | 'nearbyPropertyType'

// api query 조작시 실제로 사용하는 key. 만약 지도 이동시 키가 유실되는걸 원하지 않으면 이쪽에 추가해준다.
export const FILTER_KEYS_MAP_MOVE: Array<
  keyof BuildingsDataV1BuildingsGetParams | keyof SearchParams | NearByDataParams | 'selectedTab'
> = [
  'pageNum',
  'pageSize',
  'neLat',
  'neLng',
  'swLat',
  'swLng',
  'paths',
  'circleLat',
  'circleLng',
  'radius',
  'pointLat',
  'pointLng',
  'orderByGroup',
  'regionCode',
  'boeTypeCode',
  'propertyTypeCode',
  'minTotalArea',
  'maxTotalArea',
  'constructStatusCode',
  'lwhTypeCode',
  'minConstructDateY',
  'maxConstructDateY',
  'minWalkingDistanceSubway',
  'maxWalkingDistanceSubway',
  'minWalkingTimeSubway',
  'maxWalkingTimeSubway',
  'ownerTypeCode',
  'ownerName',
  'minRentFeePerM2',
  'maxRentFeePerM2',
  'minMaintenanceFeePerM2',
  'maxMaintenanceFeePerM2',
  'minNocPerM2',
  'maxNocPerM2',
  'minEmptyRate',
  'maxEmptyRate',
  'minTradeDateYmd',
  'maxTradeDateYmd',
  'minTradePricePerM2',
  'maxTradePricePerM2',
  'minCapRate',
  'maxCapRate',
  'minTradeArea',
  'maxTradeArea',
  'raId',
  'regionCode',
  'pnu',
  'searchType',
  'raIds',
  'nearbyRaId', // 분석페이지와 빌딩상세를 분리하기 위해서 유지하는 대상으로 추가
  'nearbyPropertyType',
  'selectedTab', // 마이페이지의 보고있는 경로 대응
]

// buildings api에서 사용하는 query key 모음
export const FILTER_KEYS_BUILDINGS_API: Array<keyof BuildingsDataV1BuildingsGetParams> = [
  'pageNum',
  'pageSize',
  'neLat',
  'neLng',
  'swLat',
  'swLng',
  'paths',
  'circleLat',
  'circleLng',
  'radius',
  'pointLat',
  'pointLng',
  'orderByGroup',
  'regionCode',
  'boeTypeCode',
  'propertyTypeCode',
  'minTotalArea',
  'maxTotalArea',
  'constructStatusCode',
  'lwhTypeCode',
  'minConstructDateY',
  'maxConstructDateY',
  'registGubunCode',
  'minWalkingDistanceSubway',
  'maxWalkingDistanceSubway',
  'minWalkingTimeSubway',
  'maxWalkingTimeSubway',
  'ownerTypeCode',
  'ownerName',
  'minRentFeePerM2',
  'maxRentFeePerM2',
  'minMaintenanceFeePerM2',
  'maxMaintenanceFeePerM2',
  'minNocPerM2',
  'maxNocPerM2',
  'minEmptyRate',
  'maxEmptyRate',
  'minEmptyArea',
  'maxEmptyArea',
  'lessee',
  'minTradeDateYmd',
  'maxTradeDateYmd',
  'minTradePricePerM2',
  'maxTradePricePerM2',
  'minTradePrice',
  'maxTradePrice',
  'minCapRate',
  'maxCapRate',
  'minTradeArea',
  'maxTradeArea',
  'buyerName',
  'sellerName',
  'buyerTypeCode',
  'sellerTypeCode',
  'rentFeeRateOfChangeType',
  'minRentFeeRateOfChange',
  'maxRentFeeRateOfChange',
  'maintenanceFeeRateOfChangeType',
  'minMaintenanceFeeRateOfChange',
  'maxMaintenanceFeeRateOfChange',
  'nocRateOfChangeType',
  'minNocRateOfChange',
  'maxNocRateOfChange',
  'emptyRateRateOfChangeType',
  'minEmptyRateRateOfChange',
  'maxEmptyRateRateOfChange',
  'isLesseeInQoq',
  'isLesseeInYoy',
  'isLesseeOutQoq',
  'isLesseeOutYoy',
]

export type BuildingsFilterParams = Omit<
  GetBuildingsMarkerDataV1BuildingsMarkerGetParams,
  'pageNum' | 'pageSize' | 'orderByGroup'
>

export type PermissionFilterParams = GetPermissionsMarkerDataV1LandsPermissionsAggregationsMarkerGetParams

export const PERMISSION_FILTER_KEYS_FROM_UI: Array<keyof PermissionFilterParams> = [
  'paths',
  'circleLat',
  'circleLng',
  'radius',
  'pointLat',
  'pointLng',
  'neLat',
  'neLng',
  'swLat',
  'swLng',
  'locationYn',
  'archGubuns',
  'minReferenceDateYm',
  'permissionSteps',
  'mainPurposeIndicationCodes',
  'minTotalArea',
  'maxTotalArea',
  'minPlatArea',
  'maxPlatArea',
  'pnu',
]

// 빌딩 정보를 가져올때 공유되는 필터의 항목이 담겨있는 키들. 필터가 추가되면 이쪽에 같이 추가해준다.
export const FILTER_KEYS_FROM_UI: Array<keyof BuildingsFilterParams> = [
  'paths',
  'circleLat',
  'circleLng',
  'radius',
  'pointLat',
  'pointLng',
  'regionCode',
  'boeTypeCode',
  'propertyTypeCode',
  'minTotalArea',
  'maxTotalArea',
  'constructStatusCode',
  'lwhTypeCode',
  'minConstructDateY',
  'maxConstructDateY',
  'registGubunCode',
  'minWalkingDistanceSubway',
  'maxWalkingDistanceSubway',
  'minWalkingTimeSubway',
  'maxWalkingTimeSubway',
  'ownerTypeCode',
  'ownerName',
  'minRentFeePerM2',
  'maxRentFeePerM2',
  'minMaintenanceFeePerM2',
  'maxMaintenanceFeePerM2',
  'minNocPerM2',
  'maxNocPerM2',
  'minEmptyRate',
  'maxEmptyRate',
  'minEmptyArea',
  'maxEmptyArea',
  'lessee',
  'minTradeDateYmd',
  'maxTradeDateYmd',
  'minTradePricePerM2',
  'maxTradePricePerM2',
  'minTradePrice',
  'maxTradePrice',
  'minCapRate',
  'maxCapRate',
  'minTradeArea',
  'maxTradeArea',
  'buyerName',
  'sellerName',
  'buyerTypeCode',
  'sellerTypeCode',
  'boeTypeCode',
  'rentFeeRateOfChangeType',
  'minRentFeeRateOfChange',
  'maxRentFeeRateOfChange',
  'maintenanceFeeRateOfChangeType',
  'minMaintenanceFeeRateOfChange',
  'maxMaintenanceFeeRateOfChange',
  'nocRateOfChangeType',
  'minNocRateOfChange',
  'maxNocRateOfChange',
  'emptyRateRateOfChangeType',
  'minEmptyRateRateOfChange',
  'maxEmptyRateRateOfChange',
  'isLesseeInQoq',
  'isLesseeInYoy',
  'isLesseeOutQoq',
  'isLesseeOutYoy',
]

export type BuildingsFilterDialogParams = Omit<
  GetBuildingsMarkerDataV1BuildingsMarkerGetParams,
  | 'pageNum'
  | 'pageSize'
  | 'orderBy'
  | 'neLat'
  | 'neLng'
  | 'swLat'
  | 'swLng'
  | 'paths'
  | 'circleLat'
  | 'circleLng'
  | 'radius'
  | 'pointLat'
  | 'pointLng'
  | 'orderByGroup'
  | 'regionCode'
>

// 필터 다이얼로그에서 사용하는 필터용 키들. 만약 필터 종류가 추가된다면 이쪽에 값을 넣어준다.
export const BUILDINGS_FILTER_KEYS_FROM_DIALOG: Array<keyof BuildingsFilterDialogParams> = [
  'propertyTypeCode',
  'minTotalArea',
  'maxTotalArea',
  'constructStatusCode',
  'lwhTypeCode',
  'minConstructDateY',
  'maxConstructDateY',
  'registGubunCode',
  'minWalkingDistanceSubway',
  'maxWalkingDistanceSubway',
  'minWalkingTimeSubway',
  'maxWalkingTimeSubway',
  'ownerTypeCode',
  'ownerName',
  'minRentFeePerM2',
  'maxRentFeePerM2',
  'minMaintenanceFeePerM2',
  'maxMaintenanceFeePerM2',
  'minNocPerM2',
  'maxNocPerM2',
  'minEmptyRate',
  'maxEmptyRate',
  'minEmptyArea',
  'maxEmptyArea',
  'lessee',
  'minTradeDateYmd',
  'maxTradeDateYmd',
  'minTradePricePerM2',
  'maxTradePricePerM2',
  'minTradePrice',
  'maxTradePrice',
  'minCapRate',
  'maxCapRate',
  'minTradeArea',
  'maxTradeArea',
  'buyerName',
  'sellerName',
  'buyerTypeCode',
  'sellerTypeCode',
  'boeTypeCode',
  'rentFeeRateOfChangeType',
  'minRentFeeRateOfChange',
  'maxRentFeeRateOfChange',
  'maintenanceFeeRateOfChangeType',
  'minMaintenanceFeeRateOfChange',
  'maxMaintenanceFeeRateOfChange',
  'nocRateOfChangeType',
  'minNocRateOfChange',
  'maxNocRateOfChange',
  'emptyRateRateOfChangeType',
  'minEmptyRateRateOfChange',
  'maxEmptyRateRateOfChange',
  'isLesseeInQoq',
  'isLesseeInYoy',
  'isLesseeOutQoq',
  'isLesseeOutYoy',
]

export type LandsFilterParams = Omit<
  GetLandMarkerDataV1LandsMarkerGetParams,
  'pageNum' | 'pageSize' | 'orderByGroup'
> & { registrationRightfulPerson?: string; registrationObjectiveKeys?: RegistrationObjectiveKey[] }

// 공공데이터 정보를 가져올때 공유되는 필터의 항목이 담겨있는 키들. 필터가 추가되면 이쪽에 같이 추가해준다.
export const LAND_FILTER_KEYS_FROM_UI: Array<keyof LandsFilterParams> = [
  'isBuilding',
  'regionCode',
  'mainPurposeIndicationCodes',
  'registGubunNames',
  'isPermission',
  'isRealStartConstruction',
  'minConstructDateY',
  'maxConstructDateY',
  'minTotalArea',
  'maxTotalArea',
  'minPlatArea',
  'maxPlatArea',
  'minArchArea',
  'maxArchArea',
  'landPurposeNames',
  'ldcgNames',
  'minLandArea',
  'maxLandArea',
  'minOfficialLandPrice',
  'maxOfficialLandPrice',
  'realTradeTypeNames',
  'realTradeMainPurposeIndicationCodes',
  'realTradeLdcgNames',
  'minRealTradeDateYm',
  'maxRealTradeDateYm',
  'minRealTradePrice',
  'maxRealTradePrice',
  'minRealTradePricePerTotalNlaArea',
  'maxRealTradePricePerTotalNlaArea',
  'minRealTradePricePerLandArea',
  'maxRealTradePricePerLandArea',
  'isBuilding',
  'circleLat',
  'circleLng',
  'radius',
  'paths',
  'pnus',
  'baseRegistrationReceiptDateYmd',
  'registrationObjectives',
]

/**
 * 공공데이터 필터 다이얼로그에서 사용하는 필터용 키들. 만약 필터 종류가 추가된다면 이쪽에 값을 넣어준다.
 * registrationRightfulPerson 의 경우 LandsFilterParams 에는 존재하지 않지만, 필터 다이얼로그에 표시해야 하므로 별도의 타입으로 선언해 추가합니다.
 */
export const LANDS_FILTER_KEYS_FROM_DIALOG: Array<keyof LandsFilterParams> = [
  'isBuilding',
  'mainPurposeIndicationCodes',
  'registGubunNames',
  'isPermission',
  'isRealStartConstruction',
  'minConstructDateY',
  'maxConstructDateY',
  'minTotalArea',
  'maxTotalArea',
  'minPlatArea',
  'maxPlatArea',
  'minArchArea',
  'maxArchArea',
  'landPurposeNames',
  'ldcgNames',
  'minLandArea',
  'maxLandArea',
  'minOfficialLandPrice',
  'maxOfficialLandPrice',
  'realTradeTypeNames',
  'minRealTradeDateYm',
  'maxRealTradeDateYm',
  'minRealTradePrice',
  'maxRealTradePrice',
  'minRealTradePricePerTotalNlaArea',
  'maxRealTradePricePerTotalNlaArea',
  'minRealTradePricePerLandArea',
  'maxRealTradePricePerLandArea',
  'pnus',
  'baseRegistrationReceiptDateYmd',
  'registrationObjectives',
  'registrationObjectiveKeys',
  'registrationRightfulPerson',
]

// 지도에서 상호작용하는 검색, 반경, 다각형등의 키들
export const FILTER_KEYS_FROM_MAP: Readonly<Array<string>> = [
  'raId',
  'propertyType',
  'boeTypeCode',
  'regionCode',
  'pnu',
  'searchType',
  'radius',
  'circleLat',
  'circleLng',
  'paths',
]

export const EXCLUDE_KEYS_AREA_SEARCH: Readonly<Array<string>> = [
  'boeTypeCode',
  'radius',
  'circleLat',
  'circleLng',
  'paths',
]

// value 값으로 공통 코드를 사용하는 필터 항목들
export const FILTER_COMMON_CODE_VALUE: Array<string> = [
  'boeTypeCode',
  'buyerTypeCode',
  'constructStatusCode',
  'lwhTypeCode',
  'ownerTypeCode',
  'propertyTypeCode',
  'registGubunCode',
  'sellerTypeCode',
  'mainPurposeIndicationCodes',
  'propertyTypeCodes',
  'lwhTypeCodes',
  'mainPurposeCodes',
  'floorIndicationCodes',
  'productTradeStateCodes',
]

// min, max를 key값으로 object로 이루어진 필터 항목들
export const FILTER_MIN_MAX_VALUE: Array<string> = [
  'constructDateY',
  'constructOrSupplyDateY',
  'totalArea',
  'walkingTimeSubway',
  'walkingDistanceSubway',
  'rentFeePerM2',
  'rentFeeRateOfChange',
  'maintenanceFeePerM2',
  'maintenanceFeeRateOfChange',
  'nocPerM2',
  'nocRateOfChange',
  'emptyRate',
  'emptyRateRateOfChange',
  'emptyArea',
  'tradePricePerM2',
  'tradePrice',
  'tradeDateYmd',
  'capRate',
  'tradeArea',
  'leaseArea',
]

// 면적 필터 항목들
export const FILTER_AREA_ITEMS: Array<string> = [
  'totalArea',
  'emptyArea',
  'tradeArea',
  'platArea',
  'archArea',
  'landArea',
  'rentArea',
]

// 원/평 단위를 가지는 필터 항목들
export const FILTER_PRICE_PER_AREA_ITEMS: Array<string> = ['rentFeePerM2', 'maintenanceFeePerM2', 'nocPerM2']

// 만원/평 단위를 가지는 필터 항목들
export const FILTER_MAN_PRICE_PER_AREA_ITEMS: Array<string> = [
  'tradePricePerM2',
  'officialLandPrice',
  'realTradePricePerTotalNlaArea',
  'realTradePricePerLandArea',
]

// 억,백만달러/평 단위를 가지는 필터 항목들
export const FILTER_EOK_PRICE_PER_AREA_ITEMS: Array<string> = ['tradePrice', 'realTradePrice']

// yyyy-MM 형태의 날짜로 표시해줘야 되는 필터 항목들
export const FILTER_DATE_ITEMS: Array<string> = ['tradeDateYmd', 'realTradeDateYm']

// yyyy-MM-dd 형태의 날짜까지 표시해줘야 되는 필터 항목들
export const FILTER_DAY_DATE_ITEMS: Array<string> = ['registrationCompletedDateYmd', 'registrationChangeDateYmd']

export const FILTER_KEYS_ITEM_SERCH: Readonly<Array<string>> = ['raId', 'propertyType', 'pnu', 'searchType']

export const FILTER_KEYS_SELECT_BOE: Readonly<Array<keyof BuildingsFilterParams>> = ['boeTypeCode']

export const FILTER_KEYS_REGION_SEARCH: Readonly<Array<keyof BuildingsFilterParams>> = ['regionCode']

export const FILTER_KEYS_LAND_SEARCH: Readonly<Array<string>> = ['pnu', 'searchType']

export const FILTER_KEYS_BOE_SEARCH: Readonly<Array<keyof BuildingsFilterParams>> = ['boeTypeCode']

export const FILTER_KEYS_CIRCLE_DRAW: Readonly<Array<keyof BuildingsFilterParams>> = [
  'radius',
  'circleLat',
  'circleLng',
]

export const FILTER_KEYS_POLYGON_DRAW: Readonly<Array<keyof BuildingsFilterParams>> = ['paths']

export const FILTER_KEYS_MAP_POSITION: Readonly<Array<string>> = ['lat', 'lng', 'zoomLevel']

export const FILTER_KEYS_MAP_BOUNDS: Readonly<Array<string>> = ['swLat', 'swLng', 'neLat', 'neLng']

export const FILTER_KEYS_SAVE = [
  ...FILTER_KEYS_FROM_MAP,
  ...FILTER_KEYS_FROM_UI,
  ...FILTER_KEYS_REGION_SEARCH,
  ...FILTER_KEYS_LAND_SEARCH,
  ...FILTER_KEYS_CIRCLE_DRAW,
  ...FILTER_KEYS_POLYGON_DRAW,
  ...FILTER_KEYS_MAP_POSITION,
  ...FILTER_KEYS_MAP_BOUNDS,
] as const

export const FILTER_NEARBY_FACILITY = [
  ...FILTER_KEYS_MAP_BOUNDS,
  ...FILTER_KEYS_CIRCLE_DRAW,
  ...FILTER_KEYS_POLYGON_DRAW,
] as const

export const NUMBER_FILTER_RANGE = {
  min: 0,
  max: 9999999,
}

export const BUILDINGS_PAGE_SIZE = 40

export const INITIAL_ZOOM_LEVEL = NMapZoomLevel['30km']
export const INITIAL_CENTER = { lat: 36.3256789, lng: 127.3242042 }

export const MIN_ZOOM_LEVEL = NMapZoomLevel['100km']
export const MAX_ZOOM_LEVEL = NMapZoomLevel['10m']

export const MAP_MOVING_DISABLED = {
  draggable: false,
  disableDoubleClickZoom: true,
  disableDoubleTapZoom: true,
  scrollWheel: false,
}

export const MAP_MOVING_ENABLED = {
  draggable: true,
  disableDoubleClickZoom: false,
  disableDoubleTapZoom: false,
  scrollWheel: true,
}

export const CONTENTS_NAVER_MAP_OPTIONS = {
  mapDataControl: false,
  tileTransition: false,
  disableDoubleClickZoom: true,
  scrollWheel: false,
  zoomControl: false,
}

export const LAYER_LEVEL_COLORS = [
  'green-200',
  'green-300',
  'green-400',
  'green-500',
  'green-600',
  'green-700',
  'green-800',
] as const

export const LAND_MARKER_HEIGHT = 38

export const DETAIL_NAVBAR_HEIGHT = 56
export const DETAIL_TAB_HEIGHT = 56
export const DETAIL_GROUP_SECTION_HEIGHT = 68
export const DETAIL_STICKY_AREA_HEIGHT = DETAIL_NAVBAR_HEIGHT + DETAIL_TAB_HEIGHT
export const DETAIL_STICKY_AREA_HEIGHT_WITH_GROUP = DETAIL_STICKY_AREA_HEIGHT + DETAIL_GROUP_SECTION_HEIGHT

export const SINGLE_VALUE_ANCHOR_Y = 63
export const DUAL_VALUE_ANCHOR_Y = 83

export const SUB_VALUE_ANCHOR_Y = 81

// 필수로 보여져야 할 fields
export const BUILDING_REQUIRED_FIELD = ['raId', 'buildingName', 'propertyTypeCode']

// 초기상태일 때 기본으로 보여져야 할 fields
export const BUILDING_INITIAL_VISIBLE_FIELD = [
  'constructStatusCode',
  'address',
  'totalArea',
  'constructDateY',
  'remodelingDateY',
  'platArea',
  'archArea',
  'bcRat',
  'vlRat',
  'floorCnt',
  'ownerName',
  'officeBoe',
  'rentFeePerM2',
  'nocPerM2',
  'emptyRate',
  'lwhBoe',
  'roomRentFeePerM2',
  'lowRentFeePerM2',
  'roomEmptyRate',
  'lowEmptyRate',
]

export const LAND_REQUIRED_FIELD = ['pnu', 'address']

export const LAND_INITIAL_VISIBLE_FIELD = [
  'ldcgName',
  'landArea',
  'officialLandPrice',
  'buildingName',
  'registGubunName',
  'mainPurposeName',
  'useAprDateYmd',
  'totalArea',
  'floorCnt',
  'realTradeTypeName',
  'realTradeDateYmd',
  'realTradePrice',
]

export const TABLE_VIEW_WITH_DETAIL_SPACE = 520

export const TIMEOUT_LAND_ANALYSIS = millisecondsInSecond * 30

export const DETAIL_FOOTER_BUTTONS_Z_INDEX = 103

export type BuildingTableViewFieldType = keyof BuildingsDetailsOut | 'officeBoe' | 'lwhBoe'

export type LandTableViewFieldType = keyof LandDetailOut

export const ANALYSIS_PAGE_SIZE = 10

export const REGISTRATION_OBJECTIVE_KEYS = [
  'transfer_of_ownership',
  'establishment_of_mortgage_lien',
  'transfer_of_mortgage_lien',
  'cancellation_of_mortgage_lien',
  'establishment_of_jeonse_right',
  'transfer_of_jeonse_right',
  'cancellation_of_jeonse_right',
  'establishment_of_leasehold',
  'transfer_of_leasehold',
  'cancellation_of_leasehold',
] as const

export const REGISTRATION_OBJECTIVE_VALUE_MAP: Record<RegistrationObjectiveKey, string> = {
  transfer_of_ownership: '소유권이전',
  establishment_of_mortgage_lien: '근저당권설정',
  transfer_of_mortgage_lien: '근저당권변경,근저당권이전',
  cancellation_of_mortgage_lien: '근저당권말소',
  establishment_of_jeonse_right: '전세권설정',
  transfer_of_jeonse_right: '전세권변경,전세권이전',
  cancellation_of_jeonse_right: '전세권말소',
  establishment_of_leasehold: '임차권설정',
  transfer_of_leasehold: '임차권변경,임차권이전',
  cancellation_of_leasehold: '임차권말소',
}

export type RegistrationObjectiveKey = (typeof REGISTRATION_OBJECTIVE_KEYS)[number]

export const CAROUSEL_INTERVAL = 7000

export const PLACE_SHOW_CLUSTER_COUNT = 500

export type OfficePropertyDialogCardModel = PropertyDialogCardModel & {
  propertyGroups: { list: Property<keyof OfficeOut>[]; name?: string }[]
}

export type LwhPropertyDialogCardModel = PropertyDialogCardModel & {
  propertyGroups: { list: Property<keyof LwhOut>[]; name?: string }[]
}

export const FLEXIBLE_MARKER_WIDTH = 10

export const DEFAULT_LESSEE_UPDATE_DATE = '20240305'
