import React, { FC, useEffect, useRef, useState } from 'react'
import { GetUsersInquiryResponseInquiryInquiryTarget } from '~/libs/apis/service/model'
import { useDebounce } from '@toss/react'
import Box from '~/components/Box/Box'
import { useTranslation } from 'react-i18next'
import { EmitType } from '@syncfusion/ej2-base'
import { ChangedEventArgs } from '@syncfusion/ej2-inputs/src/textbox/textbox'
import BasicSearch from '~/components/Search/BasicSearch'
import NoSearch from '~/templates/common/search/NoSearch'
import SearchContent from '~/templates/common/search/SearchContent'
import SearchListItem from '~/components/Search/SearchListItem'
import useControlSearchKey from '~/libs/hooks/useControlSearchKey'
import { type SearchBuildingOut, type SearchLandOut } from '~/libs/apis/data/model'
import { BUILDING_SEARCH_SIZE, isSearchLandOut } from '~/libs/utils/search'
import useGetTotalOfficeSearch from '~/libs/hooks/useGetTotalOfficeSearch'
import { useImpressionRef } from '@toss/impression-area'
import ScrollBox from '~/components/Box/ScrollBox'

interface InquiryTargetFieldProps {
  onClick: (data: GetUsersInquiryResponseInquiryInquiryTarget) => void
}
const InquiryTargetSearch: FC<InquiryTargetFieldProps> = ({ onClick }) => {
  const { t } = useTranslation('place', { keyPrefix: 'place_msg' })
  const { t: inquiryTerm } = useTranslation('common', { keyPrefix: 'data_inquiry' })

  const [active, setActive] = useState(false)
  const [keyword, setKeyword] = useState('')
  const [keepPreviousData, setKeepPreviousData] = useState(false)
  const [searchResultHeight, setSearchResultHeight] = useState(0)
  const searchRef = useRef<HTMLInputElement>(null)
  const ref = useImpressionRef({
    onImpressionEnd: () => {
      setActive(false)
    },
  })

  const { totalSearchList, buildings, lands } = useGetTotalOfficeSearch(
    {
      keyword: keyword,
      buildingSize: BUILDING_SEARCH_SIZE.buildingSize,
      landSize: BUILDING_SEARCH_SIZE.landSize,
      regionSize: BUILDING_SEARCH_SIZE.regionSize,
    },
    {
      enabled: Boolean(keyword) && keyword.length >= 2,
      keepPreviousData,
    },
  )

  useEffect(() => {
    if (searchRef?.current) {
      const inputBottom = searchRef.current.getBoundingClientRect().bottom
      const viewportHeight = window.innerHeight
      const resultHeight = viewportHeight - inputBottom - 100
      setSearchResultHeight(resultHeight > 80 ? resultHeight : 80)
    }
  }, [active])

  useEffect(() => {
    if (!(buildings.length === 0 && lands.length === 0)) {
      setKeepPreviousData(true)
    }

    if (keyword.length === 0) {
      setKeepPreviousData(false)
    }
  }, [buildings, lands, keyword])

  const handleChange: EmitType<ChangedEventArgs> = useDebounce((value: string) => {
    if (value.length === 0) {
      setKeepPreviousData(false)
    }
    if (keyword !== value) {
      setKeyword(value)
      setActive(true)
    }
  }, 500)

  const handleClick = (item: GetUsersInquiryResponseInquiryInquiryTarget) => {
    onClick(item)
  }
  const { onKeyEvent, focusIndex, onClear } = useControlSearchKey({
    length: buildings.length + lands.length,
    onEnter: (index) => {
      const totalList = [...(lands ?? []), ...(buildings ?? [])]
      if (totalList.length > 0 && totalList.length + 1 > index) {
        const item = totalList[index]
        if (isSearchLandOut(item)) {
          handleLandClick(item)
        } else {
          handleBuildingClick(item)
        }
      }
    },
  })

  const handleBuildingClick = (item: SearchBuildingOut) =>
    handleClick({
      inquiryTargetType: 'BUILDING',
      raId: item.raId,
      buildingName: item.buildingName,
      address: item.address,
      roadAddress: item.roadAddress,
      propertyTypeCode: item.propertyTypeCode,
    })

  const handleLandClick = (item: SearchLandOut) =>
    handleClick({
      address: item.address,
      inquiryTargetType: 'PNU',
      pnu: item!.pnu,
    })

  const getBuildingClickHandler = (item: SearchBuildingOut) => () => handleBuildingClick(item)
  const getLandClickHandler = (item: SearchLandOut) => () => handleLandClick(item)

  const handleFocus = () => {
    setActive(true)
  }

  const handleBlur = () => {
    setActive(false)
    onClear()
  }

  return (
    <Box position="relative">
      <BasicSearch
        ref={searchRef}
        width="540px"
        placeholder={inquiryTerm('placeholder.inquiry_target')}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyDown={onKeyEvent}
      />
      <Box ref={ref} />
      {keyword && totalSearchList && active && (
        <Box
          width="540px"
          height={`${searchResultHeight}px`}
          position="absolute"
          maxHeight="330px"
          marginTop="8px"
          zIndex="1"
          borderRadius="8px"
          border="1px solid var(--color-gray-300)"
          boxShadow="black-04"
          backgroundColor="white"
        >
          {buildings.length + lands.length === 0 ? (
            <ScrollBox variant="vertical" height="100%">
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
                height="100%"
                minHeight="fit-content"
                p="10px 0"
              >
                <NoSearch subtitle={t('search_bar_do_search')} height="fit-content" />
              </Box>
            </ScrollBox>
          ) : (
            <SearchContent>
              {lands.map((item, index) => (
                <SearchListItem
                  key={`land-search-${item?.pnu}`}
                  title={item!.address}
                  type="land"
                  isFocused={index === focusIndex}
                  onClick={getLandClickHandler(item)}
                />
              ))}
              {buildings.map((item, index) => (
                <SearchListItem
                  key={`building-search-${item!.raId}`}
                  title={item!.buildingName}
                  type={item!.propertyTypeCode === 'PT01_1' ? 'office' : 'lwh'}
                  jibunAddress={item!.address}
                  roadNameAddress={item!.roadAddress}
                  isFocused={index + lands.length === focusIndex}
                  onClick={getBuildingClickHandler(item)}
                />
              ))}
            </SearchContent>
          )}
        </Box>
      )}
    </Box>
  )
}

export default InquiryTargetSearch
