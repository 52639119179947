/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * RA backend
 * OpenAPI spec version: 1.8.1
 */

export type LwhLeaseTrendValueType = typeof LwhLeaseTrendValueType[keyof typeof LwhLeaseTrendValueType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LwhLeaseTrendValueType = {
  roomRentFee: 'roomRentFee',
  lowRentFee: 'lowRentFee',
  roomEmptyRate: 'roomEmptyRate',
  lowEmptyRate: 'lowEmptyRate',
  roomEmptyArea: 'roomEmptyArea',
  lowEmptyArea: 'lowEmptyArea',
  roomMaintenanceFee: 'roomMaintenanceFee',
  lowMaintenanceFee: 'lowMaintenanceFee',
  roomDepositFee: 'roomDepositFee',
  lowDepositFee: 'lowDepositFee',
} as const;
