import { datadogRum } from '@datadog/browser-rum'
import { DialogUtility } from '@syncfusion/ej2-popups'
import { useCallbackOnce } from '@toss/react'
import { useTranslation } from 'next-i18next'
import { versionDataV1VersionGet } from '~/libs/apis/data/default/default'
import { getVersion as getServiceVersion } from '~/libs/apis/service/api'
import { HIGHEST_Z_INDEX } from '~/libs/constants/common'

const useNewVersionErrorDialog = () => {
  const { t } = useTranslation('common')
  return useCallbackOnce(() => {
    DialogUtility.alert({
      title: t('common_msg.new_version_title'),
      content: t('common_msg.new_version_description'),
      position: { X: 'center', Y: 'center' },
      okButton: {
        text: t('common_term.confirm'),
      },
      close: () => {
        Promise.all([
          getServiceVersion({ skipNewVersionCheck: true }).then((version) => {
            if (version?.version) {
              localStorage.setItem('x-service-api-version', version.version)
            }
          }),
          versionDataV1VersionGet({ skipNewVersionCheck: true }).then((version) => {
            if (version?.api) {
              localStorage.setItem('x-data-api-version', version.api)
            }
          }),
        ])
          .catch((error) => {
            datadogRum.addError(error)
          })
          .finally(() => location.reload())
      },
      width: '384px',
      zIndex: HIGHEST_Z_INDEX,
    })
  }, [])
}

export default useNewVersionErrorDialog
