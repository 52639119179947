/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * RA backend
 * OpenAPI spec version: 1.8.1
 */

export type KsicType = typeof KsicType[keyof typeof KsicType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const KsicType = {
  large: 'large',
  medium: 'medium',
} as const;
