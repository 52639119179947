import { useBooleanState } from '@toss/react'
import { noop } from '@toss/utils'
import { createContext, ReactNode, useContext, useRef } from 'react'
import {
  patchExcelDownloadCountDataV1UsersExcelDownloadCountPatch,
  useGetExcelDownloadCountDataV1UsersDownloadCountGet,
} from '~/libs/apis/data/users/users'
import ExportTrialDialog from '~/templates/common/dialog/ExportTrialDialog'

type ConfirmCallback = () => void

interface ExcelTrialDialogContextUtils {
  showTrialDialog: (callback: ConfirmCallback) => void
}

const ExcelTrialDialogContextContext = createContext<ExcelTrialDialogContextUtils>({
  showTrialDialog: noop,
})

const ExcelTrialDialogContextProvider = ({ children }: { children: ReactNode }) => {
  const [isTrialDialogVisible, openTrialDialog, closeTrialDialog] = useBooleanState(false)
  const { data: downloadCount, mutate: downloadCountMutate } = useGetExcelDownloadCountDataV1UsersDownloadCountGet()
  const confirmCallback = useRef<ConfirmCallback>()

  const showTrialDialog = (callback: ConfirmCallback) => {
    confirmCallback.current = callback
    openTrialDialog()
  }

  const handleTrialConfirm = () => {
    confirmCallback.current?.()
    closeTrialDialog()
    downloadCountMutate()
    patchExcelDownloadCountDataV1UsersExcelDownloadCountPatch().then(() => {
      closeTrialDialog()
      downloadCountMutate()
    })
  }

  const utils: ExcelTrialDialogContextUtils = {
    showTrialDialog,
  }

  return (
    <ExcelTrialDialogContextContext.Provider value={utils}>
      {children}
      <ExportTrialDialog
        visible={isTrialDialogVisible}
        onCancel={closeTrialDialog}
        onConfirm={handleTrialConfirm}
        count={(downloadCount?.excelLimitCount ?? 0) - (downloadCount?.excelUseCount ?? 0)}
        type="excel"
      />
    </ExcelTrialDialogContextContext.Provider>
  )
}

const useExcelTrialDialogContextDialog = () => {
  return useContext(ExcelTrialDialogContextContext)
}

export { useExcelTrialDialogContextDialog }

export default ExcelTrialDialogContextProvider
