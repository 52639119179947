import { FC, useEffect, useRef } from 'react'
import Menu from '~/templates/Menu'
import Typography from '~/components/Typography/Typography'
import Box from '~/components/Box/Box'
import Logo from '~/assets/images/logo/logo.svg'
import { useNextRouter } from '~/libs/hooks/useNextRouter'
import { HEADER_HEIGHT, HEADER_Z_INDEX } from '~/libs/constants/common'
import sizes from '~/libs/theme/sizes'
import { ColorPalette } from '~/libs/theme/colors'

const getVersionColor = (): keyof ColorPalette => {
  if (process.env.isDev) {
    return 'event-error'
  }

  if (process.env.isStg) {
    return 'blue-700'
  }

  if (process.env.isPrd) {
    return 'system-white'
  }

  return 'system-black'
}

const Header: FC = () => {
  const router = useNextRouter()
  const canOpenNav = useRef(true)

  useEffect(() => {
    const handleRouterChangeStart = () => {
      canOpenNav.current = false
    }
    const handleRouterChangeComplete = () => {
      canOpenNav.current = true
    }
    router.events.on('routeChangeStart', handleRouterChangeStart)
    router.events.on('routeChangeComplete', handleRouterChangeComplete)
    return () => {
      router.events.off('routeChangeStart', handleRouterChangeStart)
      router.events.off('routeChangeComplete', handleRouterChangeComplete)
    }
  }, [router])

  const handleBannerClick = () => {
    router.push('/place')
  }

  const handleTransitionEnd = () => {
    canOpenNav.current = true
  }

  return (
    <Box
      position="fixed"
      left={0}
      right={0}
      transitionDuration={100}
      onTransitionEnd={handleTransitionEnd}
      minWidth={sizes.windowMinimumWidth}
      height={HEADER_HEIGHT}
      display="flex"
      alignItems="center"
      zIndex={HEADER_Z_INDEX}
      backgroundColor="system-white"
      padding="0 20px"
      borderBottom="1px solid var(--color-gray-300)"
    >
      <Box display="flex" cursor="pointer" onClick={handleBannerClick} alignItems="center" gap={1}>
        <Logo width={240} height={28} />
        {!process.env.isPrd && (
          <Box>
            <Typography
              color={getVersionColor()}
              variant="h4"
              fontWeight="bold"
              whiteSpace="nowrap"
              verticalAlign="middle"
            >
              {process.env.VERSION}
            </Typography>
          </Box>
        )}
      </Box>
      <Menu />
    </Box>
  )
}

export default Header
