/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * RA backend
 * OpenAPI spec version: 1.8.1
 */

export type Yn = typeof Yn[keyof typeof Yn];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Yn = {
  N: 'N',
  Y: 'Y',
} as const;
