/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * RA backend
 * OpenAPI spec version: 1.8.1
 */

export type ProductCode = typeof ProductCode[keyof typeof ProductCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductCode = {
  REAL_ESTATE_REGISTRATION: 'REAL_ESTATE_REGISTRATION',
  ISSUANCE_OF_REAL_ESTATE_REGISTRATION: 'ISSUANCE_OF_REAL_ESTATE_REGISTRATION',
  CORPORATE_REGISTRATION: 'CORPORATE_REGISTRATION',
  BUILDING_REGISTER: 'BUILDING_REGISTER',
  ISSUANCE_OF_BUILDING_REGISTER: 'ISSUANCE_OF_BUILDING_REGISTER',
  LAND_REGISTER: 'LAND_REGISTER',
  ISSUANCE_OF_LAND_REGISTER: 'ISSUANCE_OF_LAND_REGISTER',
  LAND_USE_PLAN_CONFIRMATION: 'LAND_USE_PLAN_CONFIRMATION',
  CADASTRAL_MAP_REGISTER: 'CADASTRAL_MAP_REGISTER',
} as const;
