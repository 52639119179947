/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * RA backend
 * OpenAPI spec version: 1.8.1
 */
import useSwr from 'swr'
import type {
  Key,
  SWRConfiguration
} from 'swr'
import type {
  DownloadCountResponse
} from '../model/downloadCountResponse'
import type {
  GetUserMeResponse
} from '../model/getUserMeResponse'
import type {
  GetUsersConfigurationResponse
} from '../model/getUsersConfigurationResponse'
import type {
  PatchUserAbsolutePwdRequest
} from '../model/patchUserAbsolutePwdRequest'
import type {
  PatchUsersConfigurationRequest
} from '../model/patchUsersConfigurationRequest'
import type {
  PatchUsersInitPasswordRequest
} from '../model/patchUsersInitPasswordRequest'
import type {
  PatchUsersMeRequest
} from '../model/patchUsersMeRequest'
import type {
  PatchUsersTokenRequest
} from '../model/patchUsersTokenRequest'
import type {
  PatchUsersTokenResponse
} from '../model/patchUsersTokenResponse'
import type {
  SubscriptionPermissionsResponse
} from '../model/subscriptionPermissionsResponse'
import type {
  UserBusinessPartnerOut
} from '../model/userBusinessPartnerOut'
import type {
  UsersSignInRequest
} from '../model/usersSignInRequest'
import type {
  UsersSignInResponse
} from '../model/usersSignInResponse'
import type {
  UsersVerifyTokenResponse
} from '../model/usersVerifyTokenResponse'
import type {
  VerifyDeviceRequest
} from '../model/verifyDeviceRequest'
import type {
  VerifyDeviceResponse
} from '../model/verifyDeviceResponse'
import { raApiCall } from '../../../utils/customInstance';
import { customFormData } from '../../../utils/customFormData';


  
  // eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

/**
 * @summary 회원 거래처 조회
 */
export const getUserBusinessPartnerDataV1UsersBusinessPartnerGet = (
    
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<UserBusinessPartnerOut>(
      {url: `/data/v1/users/business-partner`, method: 'GET'
    },
      options);
    }
  

export const getGetUserBusinessPartnerDataV1UsersBusinessPartnerGetKey = () => [`/data/v1/users/business-partner`] as const;

    
export type GetUserBusinessPartnerDataV1UsersBusinessPartnerGetQueryResult = NonNullable<Awaited<ReturnType<typeof getUserBusinessPartnerDataV1UsersBusinessPartnerGet>>>
export type GetUserBusinessPartnerDataV1UsersBusinessPartnerGetQueryError = unknown

/**
 * @summary 회원 거래처 조회
 */
export const useGetUserBusinessPartnerDataV1UsersBusinessPartnerGet = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getUserBusinessPartnerDataV1UsersBusinessPartnerGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetUserBusinessPartnerDataV1UsersBusinessPartnerGetKey() : null);
  const swrFn = () => getUserBusinessPartnerDataV1UsersBusinessPartnerGet(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 다운로드 횟수 조회
 */
export const getExcelDownloadCountDataV1UsersDownloadCountGet = (
    
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<DownloadCountResponse>(
      {url: `/data/v1/users/download-count`, method: 'GET'
    },
      options);
    }
  

export const getGetExcelDownloadCountDataV1UsersDownloadCountGetKey = () => [`/data/v1/users/download-count`] as const;

    
export type GetExcelDownloadCountDataV1UsersDownloadCountGetQueryResult = NonNullable<Awaited<ReturnType<typeof getExcelDownloadCountDataV1UsersDownloadCountGet>>>
export type GetExcelDownloadCountDataV1UsersDownloadCountGetQueryError = unknown

/**
 * @summary 다운로드 횟수 조회
 */
export const useGetExcelDownloadCountDataV1UsersDownloadCountGet = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getExcelDownloadCountDataV1UsersDownloadCountGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetExcelDownloadCountDataV1UsersDownloadCountGetKey() : null);
  const swrFn = () => getExcelDownloadCountDataV1UsersDownloadCountGet(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 엑셀 다운로드 횟수 추가
 */
export const patchExcelDownloadCountDataV1UsersExcelDownloadCountPatch = (
    
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<DownloadCountResponse>(
      {url: `/data/v1/users/excel-download-count`, method: 'PATCH'
    },
      options);
    }
  

/**
 * @summary PPT 다운로드 횟수 추가
 */
export const patchPptDownloadCountDataV1UsersPptDownloadCountPatch = (
    
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<DownloadCountResponse>(
      {url: `/data/v1/users/ppt-download-count`, method: 'PATCH'
    },
      options);
    }
  

/**
 * @summary 로그인(유저의 JWT 토근 생성)
 */
export const signInDataV1UsersSignInPost = (
    usersSignInRequest: UsersSignInRequest,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<UsersSignInResponse>(
      {url: `/data/v1/users/sign-in`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: usersSignInRequest
    },
      options);
    }
  

/**
 * @summary JWT 토큰 검증
 */
export const verifyTokenDataV1UsersVerifyTokenGet = (
    
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<UsersVerifyTokenResponse>(
      {url: `/data/v1/users/verify-token`, method: 'GET'
    },
      options);
    }
  

export const getVerifyTokenDataV1UsersVerifyTokenGetKey = () => [`/data/v1/users/verify-token`] as const;

    
export type VerifyTokenDataV1UsersVerifyTokenGetQueryResult = NonNullable<Awaited<ReturnType<typeof verifyTokenDataV1UsersVerifyTokenGet>>>
export type VerifyTokenDataV1UsersVerifyTokenGetQueryError = unknown

/**
 * @summary JWT 토큰 검증
 */
export const useVerifyTokenDataV1UsersVerifyTokenGet = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof verifyTokenDataV1UsersVerifyTokenGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getVerifyTokenDataV1UsersVerifyTokenGetKey() : null);
  const swrFn = () => verifyTokenDataV1UsersVerifyTokenGet(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary JWT 토큰 갱신
 */
export const patchTokenDataV1UsersTokenPatch = (
    patchUsersTokenRequest: PatchUsersTokenRequest,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<PatchUsersTokenResponse>(
      {url: `/data/v1/users/token`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchUsersTokenRequest
    },
      options);
    }
  

/**
 * @summary 로그아웃
 */
export const signOutDataV1UsersSignOutPost = (
    
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<void>(
      {url: `/data/v1/users/sign-out`, method: 'POST'
    },
      options);
    }
  

/**
 * @summary 비밀번호 찾기를 통한 비밀번호 변경
 */
export const patchUserAbsolutePasswordDataV1UsersAbsolutePasswordPatch = (
    patchUserAbsolutePwdRequest: PatchUserAbsolutePwdRequest,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<void>(
      {url: `/data/v1/users/absolute/password`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchUserAbsolutePwdRequest
    },
      options);
    }
  

/**
 * @summary 임시비밀번호 사용시 최초 비밀번호 변경
 */
export const patchUserInitPasswordDataV1UsersInitPasswordPatch = (
    patchUsersInitPasswordRequest: PatchUsersInitPasswordRequest,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<void>(
      {url: `/data/v1/users/init-password`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchUsersInitPasswordRequest
    },
      options);
    }
  

/**
 * @summary 비밀번호 변경 시점 연장
 */
export const patchPostponePasswordUpdateDataV1UsersPasswordPostponeUpdatePatch = (
    
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<void>(
      {url: `/data/v1/users/password/postpone-update`, method: 'PATCH'
    },
      options);
    }
  

/**
 * @summary 회원 권한 조회
 */
export const getSubscriptionPermissionsDataV1UsersSubscriptionsPermissionsGet = (
    
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<SubscriptionPermissionsResponse>(
      {url: `/data/v1/users/subscriptions/permissions`, method: 'GET'
    },
      options);
    }
  

export const getGetSubscriptionPermissionsDataV1UsersSubscriptionsPermissionsGetKey = () => [`/data/v1/users/subscriptions/permissions`] as const;

    
export type GetSubscriptionPermissionsDataV1UsersSubscriptionsPermissionsGetQueryResult = NonNullable<Awaited<ReturnType<typeof getSubscriptionPermissionsDataV1UsersSubscriptionsPermissionsGet>>>
export type GetSubscriptionPermissionsDataV1UsersSubscriptionsPermissionsGetQueryError = unknown

/**
 * @summary 회원 권한 조회
 */
export const useGetSubscriptionPermissionsDataV1UsersSubscriptionsPermissionsGet = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getSubscriptionPermissionsDataV1UsersSubscriptionsPermissionsGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetSubscriptionPermissionsDataV1UsersSubscriptionsPermissionsGetKey() : null);
  const swrFn = () => getSubscriptionPermissionsDataV1UsersSubscriptionsPermissionsGet(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 기기인증 요청
 */
export const verifyDeviceDataV1UsersVerifyDevicePost = (
    verifyDeviceRequest: VerifyDeviceRequest,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<VerifyDeviceResponse>(
      {url: `/data/v1/users/verify-device`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: verifyDeviceRequest
    },
      options);
    }
  

/**
 * @summary 회원 마이페이지 조회
 */
export const getUserDataV1UsersMeGet = (
    
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<GetUserMeResponse>(
      {url: `/data/v1/users/me`, method: 'GET'
    },
      options);
    }
  

export const getGetUserDataV1UsersMeGetKey = () => [`/data/v1/users/me`] as const;

    
export type GetUserDataV1UsersMeGetQueryResult = NonNullable<Awaited<ReturnType<typeof getUserDataV1UsersMeGet>>>
export type GetUserDataV1UsersMeGetQueryError = unknown

/**
 * @summary 회원 마이페이지 조회
 */
export const useGetUserDataV1UsersMeGet = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getUserDataV1UsersMeGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetUserDataV1UsersMeGetKey() : null);
  const swrFn = () => getUserDataV1UsersMeGet(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 회원 마이페이지 수정
 */
export const patchUserDataV1UsersMePatch = (
    patchUsersMeRequest: PatchUsersMeRequest,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<void>(
      {url: `/data/v1/users/me`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchUsersMeRequest
    },
      options);
    }
  

/**
 * @summary 회원 설정 조회
 */
export const getUserConfigurationDataV1UsersConfigurationGet = (
    
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<GetUsersConfigurationResponse>(
      {url: `/data/v1/users/configuration`, method: 'GET'
    },
      options);
    }
  

export const getGetUserConfigurationDataV1UsersConfigurationGetKey = () => [`/data/v1/users/configuration`] as const;

    
export type GetUserConfigurationDataV1UsersConfigurationGetQueryResult = NonNullable<Awaited<ReturnType<typeof getUserConfigurationDataV1UsersConfigurationGet>>>
export type GetUserConfigurationDataV1UsersConfigurationGetQueryError = unknown

/**
 * @summary 회원 설정 조회
 */
export const useGetUserConfigurationDataV1UsersConfigurationGet = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getUserConfigurationDataV1UsersConfigurationGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof raApiCall> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetUserConfigurationDataV1UsersConfigurationGetKey() : null);
  const swrFn = () => getUserConfigurationDataV1UsersConfigurationGet(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary 회원 설정 수정
 */
export const patchUserConfigurationDataV1UsersConfigurationPatch = (
    patchUsersConfigurationRequest: PatchUsersConfigurationRequest,
 options?: SecondParameter<typeof raApiCall>) => {
      return raApiCall<void>(
      {url: `/data/v1/users/configuration`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchUsersConfigurationRequest
    },
      options);
    }
  

