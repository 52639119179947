import { FC } from 'react'
import Box from '~/components/Box/Box'
import { useTranslation } from 'next-i18next'
import Typography from '~/components/Typography/Typography'
import { CSSProperties } from '@xstyled/styled-components'

interface DetailFooterProps {
  mb?: CSSProperties['marginBottom']
  height?: CSSProperties['height']
}
const DetailFooter: FC<DetailFooterProps> = ({ mb = '70px', height }) => {
  const { t } = useTranslation('common', { keyPrefix: 'detail' })
  return (
    <Box
      width="100%"
      minHeight="132px"
      height={height}
      backgroundColor="gray-200"
      display="flex"
      alignItems="center"
      justifyContent="center"
      mb={mb}
    >
      <Typography variant="body" color="gray-600">
        {t('footer')}
      </Typography>
    </Box>
  )
}

export default DetailFooter
