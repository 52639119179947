import { FC } from 'react'
import Typography from '~/components/Typography/Typography'
import LoadMoreDataGrid from '~/templates/place/detail/LoadMoreDataGrid'
import Box from '~/components/Box/Box'
import { useTranslation } from 'next-i18next'
import { ColumnModel } from '@syncfusion/ej2-grids/src/grid/models/column'
import useNilableValueAccessor from '~/components/DataGrid/hooks/useNilableValueAccessor'
import { RegistrationTradesPagingOut } from '~/libs/apis/data/model'
import { useCurrencyValueAccessor } from '~/libs/hooks/useFormat'
import useUserConfiguration from '~/libs/hooks/useUserConfiguration'

interface RegistrationTradeListGridProps {
  registrationTrade: RegistrationTradesPagingOut
  onPageChange: (page: number) => void
}

const RegistrationTradeListGrid: FC<RegistrationTradeListGridProps> = ({ registrationTrade, onPageChange }) => {
  const { t } = useTranslation('common', { keyPrefix: 'registration' })
  const data = registrationTrade?.data
  const { currencyUnit } = useUserConfiguration()
  const currencyValueAccessor = useCurrencyValueAccessor(currencyUnit)
  const columnModels: ColumnModel[] = [
    {
      field: 'registrationListNo',
      textAlign: 'Left',
      headerText: t('list_number'),
      valueAccessor: useNilableValueAccessor,
    },
    {
      field: 'registrationTradePrice',
      textAlign: 'Left',
      headerText: t('trade_price'),
      valueAccessor: currencyValueAccessor({ format: 'won' }),
    },
  ]

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Typography variant="subtitle" color="gray-800" fontWeight="semibold">
        {t('registration_trade_list')}
      </Typography>
      <LoadMoreDataGrid
        columns={columnModels}
        dataSource={data}
        pageModel={registrationTrade?.page}
        onPageChange={onPageChange}
        allowSelection={false}
      />
    </Box>
  )
}

export default RegistrationTradeListGrid
