import { FC, MouseEventHandler } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '~/components/Box/Box'
import Button from '~/components/Button/Button'
import Dialog from '~/components/Dialog/Dialog'
import Typography from '~/components/Typography/Typography'

type ExportType = 'excel' | 'ppt' | 'excel_table'

interface ExportTrialDialogProps {
  visible: boolean
  onCancel: MouseEventHandler
  onConfirm: MouseEventHandler
  count: number
  type: ExportType
}

const ExportTrialDialog: FC<ExportTrialDialogProps> = ({ visible, onCancel, onConfirm, count, type }) => {
  const { t } = useTranslation('common', { keyPrefix: 'common_msg' })
  const { t: commonTerm } = useTranslation('common', { keyPrefix: 'common_term' })
  const canDownload = count > 0

  const footerTemplate = () => (
    <Box display="flex" gap={2} justifyContent="center">
      <Button content={commonTerm('cancel')} variant="line" color="gray" width="120px" size="lg" onClick={onCancel} />
      <Button content={commonTerm('download')} width="120px" size="lg" onClick={onConfirm} disabled={!canDownload} />
    </Box>
  )

  return (
    <Dialog
      visible={visible}
      title={t('trial_export_title')}
      width="560px"
      footerTemplate={footerTemplate}
      closeOnEscape={false}
    >
      <Box display="flex" flexDirection="column" gap={5} alignItems="strech" padding="30px">
        <Typography variant="subtitle" textAlign="center">
          {t('trial_export_table_subtitle')}
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          gap={2.5}
          padding="30px"
          backgroundColor="purple-100"
          alignItems="center"
          borderRadius="8px"
        >
          <Typography variant="h5" fontWeight="semibold">
            {t(`trial_${type}_download`)}
          </Typography>
          <Typography variant="h4" color="purple-700" fontWeight="bold">
            {canDownload ? t('trial_excel_count', { number: count }) : t('trial_count_zero')}
          </Typography>
        </Box>
        {canDownload && (
          <Typography variant="subtitle" color="purple-700" fontWeight="semibold" textAlign="center">
            {t('trial_count_guide')}
          </Typography>
        )}
      </Box>
    </Dialog>
  )
}

export default ExportTrialDialog
