import { ValueAccessor } from '@syncfusion/ej2-grids/src/grid/base/type'
import { SWR_IMMUTABLE_OPTION } from '~/libs/constants/common'
import { useGetRegistrationsR3CommonCodeDataV1RegistrationsR3CommonCodeGet } from '~/libs/apis/data/registrations/registrations'
import type { RegistrationR3CommonCodeOut, RegistrationR3CommonCodeOutCodeName } from '~/libs/apis/data/model'
import { isNotNil } from '@toss/utils'

interface R3CommonCodeObject {
  codeToName: (code: string) => RegistrationR3CommonCodeOutCodeName | undefined
  filterCodes: (codes: string[]) => RegistrationR3CommonCodeOut[] | undefined
}

const useR3CommonCode = (groupCode: string): R3CommonCodeObject => {
  const { data } = useGetRegistrationsR3CommonCodeDataV1RegistrationsR3CommonCodeGet(undefined, SWR_IMMUTABLE_OPTION)

  const codeToName = (code: string) =>
    data?.find((commonCode) => commonCode.code === code && commonCode.groupCode === groupCode)?.codeName

  const filterCodes = (codes: string[]) =>
    data?.filter((commonCode) => isNotNil(commonCode.code) && codes.includes(commonCode.code))

  return { codeToName, filterCodes }
}

export const useR3CommonCodeValueAccessor = (groupCode: string): ValueAccessor => {
  const { codeToName } = useR3CommonCode(groupCode)
  return (field: string, data: Record<string, any>) => codeToName(data[field]) ?? '-'
}

export default useR3CommonCode
