/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * RA backend
 * OpenAPI spec version: 1.8.1
 */

export type UserStatus = typeof UserStatus[keyof typeof UserStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserStatus = {
  ACTIVE: 'ACTIVE',
  PENDING_CANCELLATION: 'PENDING_CANCELLATION',
  CANCELLED: 'CANCELLED',
} as const;
