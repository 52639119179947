import { FC, useState } from 'react'
import RegistrationTradeListGrid from '~/templates/place/detail/registration/RegistrationTradeListGrid'
import OwnershipInfoGrid from '~/templates/place/detail/registration/OwnershipInfoGrid'
import MessageComponent from '~/templates/place/detail/registration/MessageComponent'
import { useTranslation } from 'next-i18next'
import {
  useGetRegistrationOwnershipInfosDataV1RegistrationsRegistrationIdOwnershipInfosGet,
  useGetRegistrationTradesDataV1RegistrationsRegistrationIdTradesGet,
} from '~/libs/apis/data/registrations/registrations'

interface RegistrationGridComponentProps {
  registrationId: number
}

const PAGE_SIZE = 5
const RegistrationGridComponent: FC<RegistrationGridComponentProps> = ({ registrationId }) => {
  const { t } = useTranslation('common', { keyPrefix: 'registration' })
  const [ownershipPage, setOwnershipPage] = useState(1)
  const [registrationTradePage, setRegistrationTradePage] = useState(1)
  const { data: ownershipInfo } = useGetRegistrationOwnershipInfosDataV1RegistrationsRegistrationIdOwnershipInfosGet(
    registrationId,
    {
      pageNum: ownershipPage,
      pageSize: PAGE_SIZE,
    },
    {
      swr: {
        keepPreviousData: true,
      },
    },
  )
  const { data: registrationTrade } = useGetRegistrationTradesDataV1RegistrationsRegistrationIdTradesGet(
    registrationId,
    {
      pageNum: registrationTradePage,
      pageSize: PAGE_SIZE,
    },
    {
      swr: {
        keepPreviousData: true,
      },
    },
  )
  const handleOwnershipPageChange = (page: number) => setOwnershipPage(page)
  const handleRegistrationTradePageChange = (page: number) => setRegistrationTradePage(page)
  const hasOwnershipInfo = ownershipInfo && ownershipInfo.data?.length !== 0
  const hasRegistrationTrade = registrationTrade && registrationTrade.data?.length !== 0

  if (!hasOwnershipInfo && !hasRegistrationTrade) {
    return <MessageComponent message={t('no_grid_information')} />
  }

  return (
    <>
      {hasOwnershipInfo && <OwnershipInfoGrid ownershipInfo={ownershipInfo} onPageChange={handleOwnershipPageChange} />}
      {hasRegistrationTrade && (
        <RegistrationTradeListGrid
          registrationTrade={registrationTrade}
          onPageChange={handleRegistrationTradePageChange}
        />
      )}
    </>
  )
}

export default RegistrationGridComponent
