/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * RA backend
 * OpenAPI spec version: 1.8.1
 */

export type AreaType = typeof AreaType[keyof typeof AreaType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AreaType = {
  M2: 'M2',
  PY: 'PY',
} as const;
