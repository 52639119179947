import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '~/components'
import Typography from '~/components/Typography/Typography'
import GuideContentDot from '~/templates/common/popover/GuideContentDot'
import GuideGridTable from '~/templates/topic/lease-product/guide/GuideGridTable'

interface LeaseProductGuideProps {
  showDetail?: boolean
}

const LeaseProductGuide: FC<LeaseProductGuideProps> = ({ showDetail = true }) => {
  const { t } = useTranslation('common', { keyPrefix: 'guide_popover' })
  const { t: common } = useTranslation('common', { keyPrefix: 'common_term' })
  const leaseProductGuides = t('lease_product_guides', { returnObjects: true })

  const gridColumns = [common('item_name'), common('description')]
  const gridItemKeys = ['product_create_date', 'floor', 'storage_type', 'noc']
  const gridContents = gridItemKeys.map((key, index) => ({
    item: common(key),
    description: leaseProductGuides[3].contents[index],
  }))

  const GuideSection = ({ title, contents }: { title: string; contents: string[] }) => (
    <Box display="flex" flexDirection="column">
      <Typography variant="h5" fontWeight="semibold" color="blue-700" pb="6px">
        {title}
      </Typography>
      {contents.map((content, index) => (
        <Box key={index} display="flex">
          <GuideContentDot variant="subtitle" />
          <Typography variant="subtitle" color="gray-800">
            {content}
          </Typography>
        </Box>
      ))}
    </Box>
  )

  return (
    <Box display="flex" flexDirection="column" gap="24px">
      <Box display="flex" flexDirection="column">
        <Typography variant="h5" fontWeight="semibold" color="blue-700" pb="6px">
          {leaseProductGuides[0].title}
        </Typography>
        <Typography variant="subtitle" color="gray-800">
          {leaseProductGuides[0].contents[0]}
        </Typography>
        <Box display="flex">
          <GuideContentDot variant="subtitle" />
          <Typography variant="subtitle" color="gray-800">
            {leaseProductGuides[0].contents[1]}
          </Typography>
        </Box>
        <Box display="flex">
          <GuideContentDot variant="subtitle" />
          <Typography variant="subtitle" color="gray-800">
            {leaseProductGuides[0].contents[2]}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography variant="h5" fontWeight="semibold" color="blue-700" pb="6px">
          {leaseProductGuides[1].title}
        </Typography>
        <Box display="flex">
          <GuideContentDot variant="subtitle" />
          <Typography variant="subtitle" color="gray-800" fontWeight="semibold">
            {leaseProductGuides[1].contents[0]}
          </Typography>
        </Box>
        <Box display="flex">
          <GuideContentDot variant="subtitle" />
          <Typography variant="subtitle" color="gray-800" fontWeight="semibold">
            {leaseProductGuides[1].contents[1]}
          </Typography>
        </Box>
        <Box display="flex" pt="24px">
          <GuideContentDot variant="subtitle" />
          <Typography variant="subtitle" color="gray-800">
            {leaseProductGuides[1].contents[2]}
          </Typography>
        </Box>
        <Box display="flex">
          <GuideContentDot variant="subtitle" />
          <Typography variant="subtitle" color="gray-800">
            {leaseProductGuides[1].contents[3]}
          </Typography>
        </Box>
        <Box display="flex">
          <GuideContentDot variant="subtitle" />
          <Typography variant="subtitle" color="gray-800">
            {leaseProductGuides[1].contents[4]}
          </Typography>
        </Box>
        <Box display="flex">
          <GuideContentDot variant="subtitle" />
          <Typography variant="subtitle" color="gray-800">
            {leaseProductGuides[1].contents[5]}
          </Typography>
        </Box>
      </Box>
      {showDetail && (
        <Box display="flex" flexDirection="column">
          <Typography variant="h5" fontWeight="semibold" color="blue-700" pb="6px">
            {leaseProductGuides[2].title}
          </Typography>
          <Box display="flex">
            <GuideContentDot variant="subtitle" />
            <Typography variant="subtitle" color="gray-800">
              {leaseProductGuides[2].contents[0]}
            </Typography>
          </Box>
          <Box display="flex">
            <GuideContentDot variant="subtitle" paddingLeft="32px" />
            <Typography variant="subtitle" color="gray-800">
              {leaseProductGuides[2].contents[1]}
            </Typography>
          </Box>
          <Box display="flex">
            <GuideContentDot variant="subtitle" paddingLeft="32px" />
            <Typography variant="subtitle" color="gray-800">
              {leaseProductGuides[2].contents[2]}
            </Typography>
          </Box>
          <Box display="flex">
            <GuideContentDot variant="subtitle" />
            <Typography variant="subtitle" color="gray-800">
              {leaseProductGuides[2].contents[3]}
            </Typography>
          </Box>
        </Box>
      )}
      <Box display="flex" flexDirection="column">
        <Typography variant="h5" fontWeight="semibold" color="blue-700" pb="6px">
          {leaseProductGuides[3].title}
        </Typography>
        <GuideGridTable headers={gridColumns} rows={gridContents} />
      </Box>
      {showDetail && <GuideSection title={leaseProductGuides[4].title} contents={leaseProductGuides[4].contents} />}
    </Box>
  )
}

export default LeaseProductGuide
