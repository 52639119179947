/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * RA backend
 * OpenAPI spec version: 1.8.1
 */

export type VerificationEmailType = typeof VerificationEmailType[keyof typeof VerificationEmailType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VerificationEmailType = {
  PASSWORD_FIND: 'PASSWORD_FIND',
  VERIFICATION_DEVICE: 'VERIFICATION_DEVICE',
} as const;
