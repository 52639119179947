import { FC } from 'react'
import { x } from '@xstyled/styled-components'

interface GuideGridTableProps {
  headers?: Array<string>
  rows: Array<Record<string, string>>
}

const GuideGridTable: FC<GuideGridTableProps> = ({ headers = [], rows }) => {
  return (
    <x.table width="100%" borderCollapse="collapse">
      {headers.length > 0 && (
        <x.thead>
          <x.tr bg="#F3F4F6" border="1px solid #E5E8ED">
            {headers.map((header, headerIndex) => (
              <x.th
                key={headerIndex}
                w={headerIndex === 0 ? '110px' : 'auto'}
                p="9px 10px"
                alignItems="center"
                textAlign="left"
                border="1px solid #E5E8ED"
                fontWeight="600"
              >
                {header}
              </x.th>
            ))}
          </x.tr>
        </x.thead>
      )}
      <x.tbody>
        {rows?.map((row, rowIndex) => (
          <x.tr key={rowIndex} border="1px solid #E5E8ED">
            {Object.keys(row).map((key, cellIndex) => (
              <x.td
                key={cellIndex}
                w={cellIndex === 0 ? '110px' : 'auto'}
                p="8px 10px"
                alignItems="center"
                textAlign="left"
                bg={cellIndex === 0 ? '#F3F4F6' : '#FFF'}
                border="1px solid #E5E8ED"
                fontWeight={cellIndex === 0 ? '600' : 'normal'}
              >
                {row[key]}
              </x.td>
            ))}
          </x.tr>
        ))}
      </x.tbody>
    </x.table>
  )
}

export default GuideGridTable
