/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * RA backend
 * OpenAPI spec version: 1.8.1
 */

export type RegistrationOpenCountChangeType = typeof RegistrationOpenCountChangeType[keyof typeof RegistrationOpenCountChangeType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RegistrationOpenCountChangeType = {
  INCREASE: 'INCREASE',
  DECREASE: 'DECREASE',
} as const;
