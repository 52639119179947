import { EmitType } from '@syncfusion/ej2-base'
import { GridModel } from '@syncfusion/ej2-grids'
import { PagerModel } from '@syncfusion/ej2-grids/src/pager/pager-model'
import { DefaultHtmlAttributes } from '@syncfusion/ej2-react-base'
import {
  ExcelExport,
  Filter,
  Freeze,
  GridComponent,
  InfiniteScroll,
  Inject,
  Page,
  PagerComponent,
  Resize,
  Selection,
  Sort,
} from '@syncfusion/ej2-react-grids'
import { useCombinedRefs } from '@toss/react'
import { isNonEmptyArray } from '@toss/utils'
import { CSSProperties, styled } from '@xstyled/styled-components'
import { forwardRef, useCallback, useEffect, useRef } from 'react'
import IconArrowLeft from '~/assets/images/icons/angle-left.svg?url'
import IconArrowRight from '~/assets/images/icons/angle-right.svg?url'
import IconArrowFirst from '~/assets/images/icons/angles-left.svg?url'
import IconArrowEnd from '~/assets/images/icons/angles-right.svg?url'
import CaretDown from '~/assets/images/icons/caret-down.svg?url'
import CaretUp from '~/assets/images/icons/caret-up.svg?url'
import { Box } from '~/components'

type PaginationMode = 'client' | 'server' | 'infinite' | undefined

export interface DataGridServerPagerModel {
  paginationMode?: PaginationMode
  pagerModel?: PagerModel
  onPageChange?: EmitType<object>
  onPageSizeChange?: EmitType<object>
}

export interface DataGridProps extends GridModel, DataGridServerPagerModel, Pick<DefaultHtmlAttributes, 'id'> {
  isLoading?: boolean
  fillPageSize?: boolean
  pagerMargin?: CSSProperties['marginTop']
}

const DEFAULT_PAGE_COUNT = 5

const DataGrid = forwardRef<GridComponent, DataGridProps>(
  (
    {
      onPageChange,
      onPageSizeChange,
      paginationMode,
      pagerModel,
      isLoading = false,
      fillPageSize = true,
      pagerMargin = '20px',
      ...gridModelProps
    },
    parentRef,
  ) => {
    const gridRef = useRef<GridComponent>(null)
    const isFirstBounded = useRef(false)
    const pagerWrapper = useRef<HTMLDivElement>(null)
    const ref = useCombinedRefs(gridRef, parentRef)

    const services: object[] = [Selection, Freeze, Resize]

    const props: GridModel = {
      locale: 'ko',
      enableHover: (!!gridModelProps.recordClick || gridModelProps.enableHover) ?? false,
      ...gridModelProps,
    }

    if (pagerModel) {
      pagerModel.pageCount ??= DEFAULT_PAGE_COUNT
    }

    if (paginationMode === 'client') {
      props.allowPaging = true
      props.pageSettings ??= pagerModel
      services.push(Page)
    }

    if (paginationMode === 'infinite') {
      props.enableInfiniteScrolling = true
      props.pageSettings ??= pagerModel
      services.push(InfiniteScroll)
    }

    if (gridModelProps.allowExcelExport) {
      services.push(ExcelExport)
    }

    if (gridModelProps.allowSorting) {
      services.push(Sort)
    }

    if (gridModelProps.allowFiltering) {
      services.push(Filter)
    }

    gridModelProps.columns?.forEach((column) => {
      if (typeof column === 'object' && !column.type) {
        column.type = 'string'
      }
    })

    const fillPageRow = () => {
      if (!gridRef.current) {
        return
      }

      if (isFirstBounded.current === false && paginationMode === 'client') {
        isFirstBounded.current = true
        pagerWrapper.current?.appendChild(gridRef.current.getPager())
      }

      const currentViewRecords = gridRef.current?.getCurrentViewRecords()
      if (isNonEmptyArray(currentViewRecords) && pagerModel?.pageSize) {
        const emptyCount = pagerModel?.pageSize - currentViewRecords.length
        for (let i = 0; i < emptyCount; i++) {
          const contentTable = gridRef.current.getContentTable() as HTMLTableElement
          if (contentTable.rows.length >= pagerModel?.pageSize) {
            break
          }
          const row = contentTable.insertRow(-1) // We are adding at the end
          row.classList.add('e-row', 'e-empty-row')
          if (props.rowHeight) {
            row.style.height = props.rowHeight + 'px'
          }
          for (let j = 0; j < gridRef.current?.getVisibleColumns().length; j++) {
            const cell = row.insertCell(j)
            cell.classList.add('e-rowcell')
          }
        }
      }
    }

    props.dataBound = (args: object) => {
      const dataBound = gridModelProps.dataBound
      if (!gridRef.current) {
        return
      }
      if (fillPageSize) {
        fillPageRow()
      }

      const content = gridRef.current?.getContentTable().parentElement
      if (content) {
        const isShowScrollView = content.scrollHeight > content.clientHeight
        const headerContent = gridRef.current.getHeaderContent() as HTMLDivElement
        if (headerContent.style && isShowScrollView) {
          headerContent.style['paddingRight'] = '10px'
          const lastColumnElement = headerContent.getElementsByClassName('e-lastcell').item(0) as HTMLTableCellElement
          if (lastColumnElement) {
            lastColumnElement.style.setProperty('border-right', 'none', 'important')
          }
        }
      }

      if (dataBound) {
        dataBound(args)
      }
    }

    const emptyRecordTemplate = useCallback(() => null, [])

    useEffect(() => {
      if (isLoading) {
        gridRef.current?.showMaskRow()
      } else {
        gridRef.current?.removeMaskRow()
      }
    }, [isLoading])

    return (
      <>
        <StyledGridComponent
          ref={ref}
          cssClass={props.allowSelection ? 'is-selection' : undefined}
          emptyRecordTemplate={emptyRecordTemplate}
          {...props}
        >
          <Inject services={services} />
        </StyledGridComponent>
        {(paginationMode === 'client' || paginationMode === 'server') && (
          <StyledPagerWrapper ref={pagerWrapper} marginTop={pagerMargin}>
            {paginationMode === 'server' && (
              <PagerComponent click={onPageChange} dropDownChanged={onPageSizeChange} {...pagerModel} />
            )}
          </StyledPagerWrapper>
        )}
      </>
    )
  },
)

const StyledPagerWrapper = styled(Box)`
  .e-pager {
    .e-pagercontainer {
      background: transparent;
    }
    .e-prevpage,
    .e-nextpage,
    .e-nextpagedisabled,
    .e-lastpagedisabled,
    .e-lastpage,
    .e-firstpage,
    .e-firstpagedisabled {
      background: transparent;
    }
    display: flex;
    justify-content: center;
    background-color: transparent;
    border: none;
    padding: 0;
    height: 18px;
    margin: 0;
    .e-pagercontainer {
      display: flex;
      gap: 8px;
      align-items: center;
      border: none;
      background: transparent;
      margin: 0;
      div {
        min-width: 20px;
        width: 20px;
        height: 18px;
      }
      .e-icon-first,
      .e-icon-last,
      .e-icon-prev,
      .e-icon-next {
        position: relative;
        padding: 0;
        border-radius: 8px;
        background: transparent;

        &:hover {
          background-color: var(--color-gray-300);
        }
      }

      .e-firstpagedisabled,
      .e-prevpagedisabled,
      .e-nextpagedisabled,
      .e-lastpagedisabled {
        cursor: default;
        &:hover {
          background-color: transparent;
        }
      }

      .e-icon-first::before,
      .e-icon-prev::before,
      .e-icon-next::before,
      .e-icon-last::before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .e-firstpage,
      .e-prevpage,
      .e-firstpagedisabled,
      .e-prevpagedisabled,
      .e-nextpage,
      .e-lastpage,
      .e-nextpagedisabled,
      .e-lastpagedisabled {
        border-right: none;
      }

      .e-icon-first::before,
      .e-icon-prev::before,
      .e-icon-last::before,
      .e-icon-next::before {
        min-width: 0;
        content: '';
        width: 10px;
        height: 10px;
      }

      .e-icon-first::before {
        background: url(${IconArrowFirst});
        background-size: cover;
      }

      .e-icon-prev::before {
        background: url(${IconArrowLeft});
        background-size: cover;
      }

      .e-icon-last::before {
        background: url(${IconArrowEnd});
        background-size: cover;
      }

      .e-icon-next::before {
        background: url(${IconArrowRight});
        background-size: cover;
      }

      div:has(> .e-nextprevitemdisabled) {
        display: none;
      }

      .e-focused {
        outline: none;
        box-shadow: none;
      }

      .e-numericcontainer {
        width: auto;
        display: flex;
        gap: 8px;
        div:has([style*='display: none']) {
          display: none;
        }
        .e-numericitem {
          font-size: 12px;
          width: 20px;
          min-width: 20px;
          height: 18px;
          display: block;
          border: none;
          line-height: 18px;
          border-radius: 8px;
          background-color: transparent;
          padding: 0 !important;
          box-shadow: none;
          font-weight: regular;

          &.e-active {
            font-weight: bold;
          }
        }

        .e-numericitem:hover {
          background-color: var(--color-gray-300);
        }

        .e-link {
          color: var(--color-gray-800);

          &.e-currentitem {
            background-color: var(--color-purple-600);
            color: #fff;
          }
        }

        .e-link:focus {
          outline: none;
        }
      }
      .e-pp,
      .e-np {
        color: var(--color-gray-800);
        background-color: transparent;
        border: none;
        font-size: 12px;
        font-weight: 300;
        line-height: 18px;
        padding: 0;
        min-width: 10px;
      }
    }

    .e-parentmsgbar {
      display: none;
    }

    .e-mfirst,
    .e-mprev,
    .e-mnext,
    .e-mlast {
      display: none;
    }
  }
`

const StyledGridComponent = styled(GridComponent)`
  &.e-grid {
    width: 100%;
    border: none;
    border-top: 1px solid var(--color-gray-300);
    border-radius: 0;

    // 전체 테이블

    .e-table:not(:has(.e-emptyrow)) {
      border: none;
      border-spacing: 0;
      border-collapse: collapse;
    }

    // 헤더 행

    .e-gridheader {
      border-top: none;
      background-color: var(--color-gray-200);

      th.e-headercell[aria-sort='ascending']:not(.e-columnselection) .e-headertext,
      th.e-headercell[aria-sort='descending']:not(.e-columnselection) .e-headertext {
        color: var(--color-purple-700);
      }

      th.e-headercell[aria-sort='none']:not(.e-columnselection)
        .e-sortfilterdiv:not(.e-icon-ascending):not(.e-icon-descending)::before,
      .e-icon-ascending::before,
      .e-icon-descending::before {
        content: '';
        position: absolute;
        display: block;
        width: 12px;
        height: 12px;
        right: 10px;
        background-color: var(--color-purple-700);
        mask: url(${CaretUp}) no-repeat center center;
        mask-size: contain;
      }

      th.e-headercell[aria-sort='none']:not(.e-columnselection)
        .e-sortfilterdiv:not(.e-icon-ascending):not(.e-icon-descending)::before {
        background-color: var(--color-gray-500);
      }
      .e-icon-descending::before {
        mask: url(${CaretDown}) no-repeat center center;
        mask-size: contain;
      }

      .e-columnheader {
        .e-headercell {
          background-color: var(--color-gray-200);
          vertical-align: middle;
          height: auto;
          padding: 13px 10px;

          border-left: 1px solid var(--color-gray-300) !important;
          border-right: 1px solid var(--color-gray-300) !important;

          .e-headercelldiv {
            height: auto;
            margin-left: 0 !important;
            margin-right: 0 !important;
            padding: 0;
          }

          .e-headercelldiv > .e-headertext {
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            color: 1px solid var(--color-gray-800);
          }
        }

        .e-headercell .e-sortfilterdiv.e-icons {
          width: 22px;
        }
      }
    }

    // 컨텐트 행

    .e-gridcontent {
      .e-headercell,
      .e-rowcell,
      .e-gridheader tr th:first-child,
      .e-gridheader tr th:last-child {
        padding: 0;
      }

      .e-content {
        overflow-y: auto !important;
        &::-webkit-scrollbar {
          background-color: var(--color-gray-100);
          height: 10px;
          width: 10px;
          border-radius: 17px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: var(--color-gray-400);
          border: 1px solid transparent;
          border-radius: 23px;
          background-clip: padding-box;
        }
      }

      tr.e-row {
        border: 1px solid var(--color-gray-300);

        &:first-child {
          border-top: 1px solid var(--color-system-white);
        }

        .e-selectionbackground {
          border: none;
          box-shadow: none;
        }

        .e-rowcell {
          color: var(--color-gray-800);
          font-size: 14px;
          padding: 10px;
          line-height: 20px;
          border: none;

          border-left: 1px solid var(--color-gray-300);

          &.e-focused.e-focus {
            box-shadow: none;
          }

          &:empty {
            height: 41px;
          }
        }

        &:nth-child(even),
        &:nth-child(even) td.e-active {
          background-color: var(--color-gray-100);
        }

        &:nth-child(odd),
        &:nth-child(odd) td.e-active {
          background-color: var(--color-system-white);
        }

        &:hover {
          &:nth-child(odd) .e-rowcell {
            background: var(--color-system-white) !important;
            color: var(--color-gray-800);
          }

          &:nth-child(even) .e-rowcell {
            background: var(--color-gray-100) !important;
            color: var(--color-gray-800);
          }
        }

        &.e-disabled {
          opacity: 1;

          .e-rowcell:hover {
            cursor: default;
          }
        }

        &.mouseover {
          cursor: pointer;
        }
      }

      &:has(.e-emptyrow) {
        height: inherit;
      }

      .e-emptyrow {
        height: 100%;

        td {
          border-style: solid;
          border-color: var(--color-gray-300);
          border-width: 0 1px 1px 1px !important;
          padding: 0 !important;
          text-align: center;
        }
      }
    }

    // 선택이 가능한 표일 경우에만

    &.is-selection .e-gridcontent {
      .e-row:not(.e-empty-row):hover,
      .e-row:has(.e-selectionbackground) {
        border: 1px double var(--color-purple-600);
        cursor: pointer;
      }

      .e-row .e-rowcell.e-selectionbackground {
        font-weight: 600;
      }
    }

    &.e-gridhover .e-gridcontent {
      .e-row:not(.e-empty-row):hover,
      .e-row:has(.e-hovered) {
        border: 1px double var(--color-purple-600);
        cursor: pointer;
      }
    }

    .e-leftfreeze {
      background-color: inherit;
    }

    & > .e-gridpager {
      display: none;
    }
  }
`

DataGrid.displayName = 'DataGrid'

export default DataGrid
