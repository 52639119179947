/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * RA backend
 * OpenAPI spec version: 1.8.1
 */

export type SimilarType = typeof SimilarType[keyof typeof SimilarType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SimilarType = {
  similar: 'similar',
  lease: 'lease',
  trade: 'trade',
} as const;
