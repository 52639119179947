import { FC, useMemo, useRef } from 'react'
import Typography from '~/components/Typography/Typography'
import LoadMoreDataGrid from '~/templates/place/detail/LoadMoreDataGrid'
import Box from '~/components/Box/Box'
import { useTranslation } from 'next-i18next'
import { ColumnModel } from '@syncfusion/ej2-grids/src/grid/models/column'
import useNilableValueAccessor from '~/components/DataGrid/hooks/useNilableValueAccessor'
import { useDetailInfo } from '~/templates/DetailTypeProvider'
import Tooltip from '~/components/Tooltip/Tooltip'
import InfoIcon from '~/assets/images/icons/info.svg'
import { TooltipComponent } from '@syncfusion/ej2-react-popups/src/tooltip'
import { useFullDetailDialog } from '~/templates/FullDetailDialogProvider'
import { RegistrationsOwnershipOut, RegistrationsOwnershipPagingOut } from '~/libs/apis/data/model'

interface OwnershipInfoGridProps {
  ownershipInfo: RegistrationsOwnershipPagingOut
  onPageChange: (page: number) => void
}

const OwnershipInfoGrid: FC<OwnershipInfoGridProps> = ({ ownershipInfo, onPageChange }) => {
  const { t } = useTranslation('common', { keyPrefix: 'registration' })
  const { t: common } = useTranslation('common', { keyPrefix: 'common_term' })
  const { detailType } = useDetailInfo()
  const isFull = detailType === 'full'
  const data = ownershipInfo?.data
  const onwershipNameRef = useRef<HTMLDivElement>(null)
  const tooltipRef = useRef<TooltipComponent>(null)
  const { openClientDialog } = useFullDetailDialog()

  const ownershipNameTemplate = (props: RegistrationsOwnershipOut) => {
    return (
      <Box
        onClick={() => {
          if (props.clientId) {
            openClientDialog(props.clientId)
          }
        }}
      >
        <Typography
          overflow="hidden"
          textOverflow="ellipsis"
          textDecoration={props.clientId ? 'underline' : undefined}
          cursor={props.clientId ? 'pointer' : 'auto'}
        >
          {props.registrationRightfulPerson}
        </Typography>
      </Box>
    )
  }

  const columnModels: ColumnModel[] = useMemo(
    () => [
      {
        field: 'registrationOwnershipType',
        width: isFull ? 100 : 46,
        textAlign: 'Left',
        headerText: common('type'),
        valueAccessor: useNilableValueAccessor,
      },
      {
        field: 'registrationRightfulPerson',
        textAlign: 'Left',
        headerTemplate: () => (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            onMouseOver={() => tooltipRef?.current?.open(onwershipNameRef.current || undefined)}
            onMouseOut={() => tooltipRef?.current?.close()}
          >
            <Typography fontWeight="semibold">{t('ownership_name')}</Typography>
            <Tooltip ref={tooltipRef} content={t('ownership_name_message')} width="225px" align="right">
              <Box ref={onwershipNameRef}>
                <InfoIcon width="12px" height="12px" color="var(--color-gray-800)" />
              </Box>
            </Tooltip>
          </Box>
        ),
        template: ownershipNameTemplate,
        clipMode: 'EllipsisWithTooltip',
      },
      {
        field: 'registrationRightfulPersonType',
        width: isFull ? 160 : 85,
        textAlign: 'Left',
        headerText: t('ownership_division'),
        valueAccessor: useNilableValueAccessor,
        clipMode: 'EllipsisWithTooltip',
      },
      {
        field: 'registrationShare',
        width: isFull ? 160 : 70,
        textAlign: 'Left',
        headerText: t('ownership_share'),
        valueAccessor: useNilableValueAccessor,
        clipMode: 'EllipsisWithTooltip',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, common],
  )

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Typography variant="subtitle" color="gray-800" fontWeight="semibold">
        {t('ownership_info')}
      </Typography>
      <LoadMoreDataGrid
        columns={columnModels}
        dataSource={data}
        pageModel={ownershipInfo?.page}
        onPageChange={onPageChange}
        allowSelection={false}
      />
    </Box>
  )
}

export default OwnershipInfoGrid
