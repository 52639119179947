/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * RA backend
 * OpenAPI spec version: 1.8.1
 */

export type Currency = typeof Currency[keyof typeof Currency];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Currency = {
  KRW: 'KRW',
  USD: 'USD',
} as const;
