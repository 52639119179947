/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * RA backend
 * OpenAPI spec version: 1.8.1
 */

export type OfficeMarketTrendBaseDateType = typeof OfficeMarketTrendBaseDateType[keyof typeof OfficeMarketTrendBaseDateType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OfficeMarketTrendBaseDateType = {
  yq: 'yq',
  y: 'y',
} as const;
