/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * RA backend
 * OpenAPI spec version: 1.8.1
 */

export type UserRole = typeof UserRole[keyof typeof UserRole];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserRole = {
  ADMIN: 'ADMIN',
  USER: 'USER',
} as const;
