/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * RA backend
 * OpenAPI spec version: 1.8.1
 */

export type LandClusterType = typeof LandClusterType[keyof typeof LandClusterType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LandClusterType = {
  sido: 'sido',
  sigungu: 'sigungu',
  bjd: 'bjd',
  grid: 'grid',
} as const;
