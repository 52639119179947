import { forwardRef, PropsWithChildren, UIEventHandler, useRef } from 'react'
import Box from '~/components/Box/Box'
import Button from '~/components/Button/Button'
import { useTranslation } from 'next-i18next'
import DetailFooter from '~/templates/DetailFooter'
import { BeforeOpenEventArgs, DialogComponent, OpenEventArgs } from '@syncfusion/ej2-react-popups'
import { EmitType } from '@syncfusion/ej2-base'
import styled from '@xstyled/styled-components'
import IconClose from '~/assets/images/icons/x-graphic.svg'
import StickyBox from '~/templates/StickyBox'
import { useDetailScrollState } from '~/templates/DetailScrollProvider'

interface TopicCommonModalProps {
  visible: boolean
  open?: () => void
  onClose: () => void
  stickyArea?: JSX.Element
  footerTemplate?: JSX.Element
  footerMargin?: number
  isModalFooterHidden?: boolean
}

const TopicCommonModal = forwardRef<DialogComponent, PropsWithChildren<TopicCommonModalProps>>(
  ({ visible, open, onClose, children, stickyArea, footerTemplate, footerMargin, isModalFooterHidden }, ref) => {
    const { t } = useTranslation('common', { keyPrefix: 'common_term' })
    const { setIsTop } = useDetailScrollState()
    const contentRef = useRef<HTMLDivElement>(null)

    const scrollToTop = () => {
      if (contentRef.current) {
        contentRef.current.scrollTop = 0
      }
    }

    const handleOpen: EmitType<OpenEventArgs> = (args) => {
      open?.()
      args.preventFocus = true
      scrollToTop()
    }

    const handleBeforeOpen: EmitType<BeforeOpenEventArgs> = (args) => {
      args.maxHeight = '100vh'
      setIsTop(true)
    }

    const handleScroll: UIEventHandler<HTMLDivElement> = (args) => {
      if (args.currentTarget.scrollTop === 0) {
        setIsTop(true)
      } else {
        setIsTop(false)
      }
    }

    return (
      <StyledDialog
        ref={ref}
        visible={visible}
        width="1000px"
        height="100vh"
        target="#dialog-target"
        position={{ X: 'center', Y: 'top' }}
        open={handleOpen}
        beforeOpen={handleBeforeOpen}
        closeOnEscape={false}
        $disableOverlayClose={true}
        isModal
      >
        <StickyBox top={0} backgroundColor="system-white">
          {stickyArea}
          <Box position="absolute" top={20} right={20} onClick={onClose} cursor="pointer">
            <IconClose width="16px" height="16px" color="var(--color-gray-700)" />
          </Box>
        </StickyBox>
        <Box
          ref={contentRef}
          height="100%"
          overflowY="scroll"
          onScroll={handleScroll}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          {children}
          {!isModalFooterHidden && <DetailFooter mb={footerMargin} />}
        </Box>
        {footerTemplate ?? (
          <StickyBox bottom={0} p={5} backgroundColor="system-white">
            <Button width="100%" size="xl" content={t('close')} color="black" onClick={onClose} />
          </StickyBox>
        )}
      </StyledDialog>
    )
  },
)

TopicCommonModal.displayName = 'TopicCommonModal'

const StyledDialog = styled(DialogComponent)`
  &.e-dialog {
    border: 0;
    border-radius: 0;

    .e-dlg-header-content {
      position: absolute;
      top: 0;
      right: -88px;
      padding: 0;
      border: 0 !important;
      border-radius: 0 !important;
    }

    .e-dlg-content {
      padding: 0;
      overflow: hidden;
    }
  }

  ::-webkit-scrollbar {
    display: none;
  }
`

export default TopicCommonModal
